<template>
  <div class="ActivityItemTask__Component">
    <div class="ActivityItemTask__Component__Edit" v-if="isOpen">
      <div class="NewTask__Component__Attributes">
        <div class="Field ml-6">
          <FieldInlineText
            :hide-hover="true"
            label="שם עיריה"
            :clearable="false"
            :filterable="false"
            :readOnly="true"
            @onChange="onChange"
            v-model="model.Name"
          />
        </div>
        <div class="Field ml-6">
          <FieldInlineText
            :hide-hover="true"
            label="כתובת"
            :clearable="false"
            :filterable="false"
            @onChange="onChange"
            v-model="model.Address"
            :changeOnBlur="true"
          />
        </div>
        <div class="Field ml-6">
          <FieldInlineSelect
            :hide-hover="true"
            label='חתימת עו"ד'
            :clearable="false"
            :filterable="false"
            :options="[
              { Value: true, Text: 'צריך' },
              { Value: false, Text: 'לא צריך' },
            ]"
            @onChange="onChange"
            v-model="model.NeedLawyerSign"
          />
        </div>
        <div class="Field ml-6">
          <FieldInlineSelect
            :hide-hover="true"
            label=' חתימת עו"ד לנהג'
            :clearable="false"
            :filterable="false"
            :options="[
              { Value: true, Text: 'צריך' },
              { Value: false, Text: 'לא צריך' },
            ]"
            @onChange="onChange"
            v-model="model.NeedLawyerSignForDriver"
          />
        </div>
        <div class="Field ml-6">
          <FieldInlineText
            :hide-hover="true"
            label="אימייל להסבה"
            :clearable="false"
            :filterable="false"
            @onChange="onChange"
            v-model="model.TransferEmail"
            :changeOnBlur="true"
          />
        </div>
        <div class="Field ml-6">
          <FieldInlineText
            :hide-hover="true"
            label="לינק לתשלום"
            :clearable="false"
            :filterable="false"
            @onChange="onChange"
            v-model="model.PaymentUrl"
            :changeOnBlur="true"
          />
        </div>
        <div class="Field ml-6">
          <FieldInlineSelect
            :hide-hover="true"
            label="TransferBotId"
            :clearable="false"
            :filterable="false"
            :optionsAsync="getTransferBotIds"
            @onChange="onChange"
            v-model="model.TransferBotId"
          />
        </div>
        <div class="Field ml-6">
          <FieldInlineText
            :hide-hover="true"
            label="TransferBotAuthorityId"
            :clearable="false"
            :filterable="false"
            @onChange="onChange"
            v-model="model.TransferBotAuthorityId"
            :changeOnBlur="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FieldInlineText from "@/components/Fields/FieldInlineText.vue";
import FieldInlineSelect from "@/components/Fields/FieldInlineSelect.vue";
import MunicipalitiesService from "@/services/MunicipalitiesService";

export default {
  name: "ActivityItemTask",
  props: ["item", "isOpen", "entityType", "entityId"],
  components: {
    FieldInlineSelect,
    FieldInlineText,
  },
  data() {
    return {
      isLoading: false,
      editTitleMode: false,
      editBodyMode: false,
      model: {
        Name: null,
        Address: null,
        NeedLawyerSign: null,
        NeedLawyerSignForDriver: null,
        TransferEmail: null,
        PaymentUrl: null,
        TransferBotId: null,
        TransferBotAuthorityId: null,
      },
    };
  },
  created() {
    this.getFullItem();
  },
  methods: {
    getFullItem() {
      MunicipalitiesService.getMunicipality(this.item.Id).then((r) => {
        this.model = { ...r.data };
      });
    },
    getTransferBotIds() {
      return MunicipalitiesService.getTransferBotTypes;
    },
    onChange(isEmpty) {
      this.hasContent = !isEmpty;
      this.update();
    },
    update() {
      this.isLoading = true;
      MunicipalitiesService.updateMunicipality(this.item.Id, this.model)
        .then(() => {
          this.$buefy.toast.open({
            type: "is-success",
            message: "ההערה נשמרה!",
            duration: 4000,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.ActivityItemTask__Component__Edit {
  padding: 15px 0;
  border-top: 1px solid #d8d8d8;
}
.Activity__Item__Box__Content__Text__Title {
  display: flex;
  align-items: start;
  padding-bottom: 15px;

  ::v-deep {
    .NewTask__Component__Editor__Actions {
      display: none;
    }
    .ProseMirror {
      min-height: unset !important;
      padding: 2px 5px;
    }
  }

  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .icon {
    color: silver;
  }
  &.complete {
    .icon {
      color: #00bda5;
    }
    span {
      text-decoration: line-through;
    }
  }
}
.NewTask__Component__Reminder {
  padding: 0 20px;

  .NewTask__Component__Reminder__Fields {
    display: flex;
    padding-bottom: 5px;

    & > div {
      width: 140px;
      display: flex;
      flex-grow: 1;
    }
  }
}
.NewTask__Component__Attributes {
  margin: 0 20px;
  padding: 0px 0;
  display: flex;
  border-bottom: 1px solid #d8d8d8;

  ::v-deep .vs__open-indicator {
    fill: #0091ae;
    transform: scale(0.6);
  }
  ::v-deep .vs__selected {
    color: #0091ae;
    font-weight: bold;
  }
  ::v-deep .vs__dropdown-toggle {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      color: #0091ae;
      cursor: pointer;
    }
  }
}
.Activity__Item__Box__Content__Text__Editor {
  padding-right: 15px;
  margin: 10px 0;
}
.Activity__Item__Box__Content__Text__Editor__Actions {
  margin-bottom: 20px;

  button {
    border: none;
    background-color: transparent;
    padding: 5px 10px;
    cursor: pointer;
    line-height: normal;
    height: unset;
    font-size: 15px;

    &:active {
      transform: scale(0.97);
    }

    &.Save {
      background-color: #486480;
      color: white;
      border-radius: 3px;
      transition: transform 0.1s cubic-bezier(0.075, 0.82, 0.165, 1);
      &:hover {
        background-color: #597794;
      }
    }
    &.Cancel {
      background-color: #d0d0d0;
      color: black;
      border-radius: 3px;
      margin-right: 5px;

      &:hover {
        background-color: #e4e4e4;
      }
    }
  }
}
.Activity__Item__Box__Content__Text__Editor {
  direction: rtl;
  padding-right: 14px;
  font-size: 14px;
  position: relative;
  color: black;
  padding: 10px 10px 30px 10px;
  border: 1px solid transparent;
  border-radius: 3px;
  max-height: 55px;
  overflow: hidden;

  &.--Title {
    padding: 0;
    margin: 0;
  }

  &:not(.open)::after {
    background-image: linear-gradient(#04040400 50%, #ffffff);
    height: 55px;
    opacity: 1;
    pointer-events: none;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    content: "";
  }
  &.open {
    max-height: unset;
    &:hover {
      background-color: #e5f5f8;
      border: 1px solid #addbff;
    }
  }
}
</style>
