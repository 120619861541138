<template>
    <div class="AdminLocationDataScan__View">
        <h1>סריקת נתוני דוחות</h1>
        <b-field label="מציג פרופילים מנוהלים">
            <b-switch v-model="isManaged">
                {{ isManaged ? 'כן' : 'לא', }}
            </b-switch>
        </b-field>
        <div class="AdminLocationDataScan__View__Form">
            <div class="right-side field">
                <b-field label="בחר פרופיל">
                    <v-select dir="rtl" label="Text" v-model="profiles" :options="users" :clearable="true" :multiple="true"
                        :searchable="true" :close-on-select="false" :placeholder="'בחר ערך מהרשימה'">
                        <div slot="no-options">לא נמצאו ערכים דומים</div>
                    </v-select>
                </b-field>
                <b-field label="בעל רכב">
                    <v-select dir="rtl" label="Text" v-model="carOwners" :options="users" :clearable="true"
                        :searchable="true" :close-on-select="true" :placeholder="'בחר ערך מהרשימה'">
                        <div slot="no-options">לא נמצאו ערכים דומים</div>
                    </v-select>
                </b-field>
                <b-field label="סוגי דוחות">
                    <v-select dir="rtl" label="Text" v-model="reportsTypes" :options="reportsOptions" :clearable="true"
                        :searchable="true" :close-on-select="true" :placeholder="'בחר ערך מהרשימה'">
                        <div slot="no-options">לא נמצאו ערכים דומים</div>
                    </v-select>
                </b-field>
                <b-field label="סוגי קרולר">
                    <v-select dir="rtl" label="Text" v-model="crawlersTypes" :options="crawlersOptions" :clearable="true"
                        :searchable="true" :close-on-select="true" :placeholder="'בחר ערך מהרשימה'">
                        <div slot="no-options">לא נמצאו ערכים דומים</div>
                    </v-select>
                </b-field>
            </div>
            <div class="left-side">
                <b-field label="עירייה">
                    <FieldInlineSelect :hide-hover="true" :clearable="true" :filterable="true" :null-is-value="true"
                        placeholder="בחר עיריה מהרשימה" :optionsAsync="getMunicipalities" @onChange="onChange"
                        v-model="city" />
                </b-field>
                <b-field label="מספר דוח">
                    <b-input v-model="reportNumber"></b-input>
                </b-field>
                <b-field label="סוג חיפוש">
                    <v-select dir="rtl" label="Text" v-model="searchTypes" :options="searchOptions" :clearable="true"
                        :searchable="true" :close-on-select="true" :placeholder="'בחר ערך מהרשימה'">
                        <div slot="no-options">לא נמצאו ערכים דומים</div>
                    </v-select>
                </b-field>
            </div>
        </div>
        <div class="dates">
            <b-field label="מתאריך">
                <b-datepicker v-model="fromDate" class="datetime" placeholder="לחץ כדי לבחור" icon-prev="chevron-right"
                    icon-next="chevron-left" icon="calendar-today" locale="en-GB"
                    :day-names="['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ש']" :month-names="[
                        'ינואר',
                        'פברואר',
                        'מרץ',
                        'אפריל',
                        'מאי',
                        'יוני',
                        'יולי',
                        'אוגוסט',
                        'ספטמבר',
                        'אוקטובר',
                        'נובמבר',
                        'דצמבר',
                    ]" close-on-click editable append-to-body>
                </b-datepicker>
            </b-field>
            <b-field label="עד תאריך">
                <b-datepicker v-model="toDate" class="datetime" placeholder="לחץ כדי לבחור" icon-prev="chevron-right"
                    icon-next="chevron-left" icon="calendar-today" locale="en-GB"
                    :day-names="['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ש']" :month-names="[
                        'ינואר',
                        'פברואר',
                        'מרץ',
                        'אפריל',
                        'מאי',
                        'יוני',
                        'יולי',
                        'אוגוסט',
                        'ספטמבר',
                        'אוקטובר',
                        'נובמבר',
                        'דצמבר',
                    ]" close-on-click editable append-to-body>
                </b-datepicker>
            </b-field>
        </div>
        <b-field label="בקש תצלום מסך לדוח">
                    <b-switch v-model="isScreenshot">
                        {{ isScreenshot ? 'כן' : 'לא', }}
                    </b-switch>
                </b-field>
        <b-button @click="importData" class="mt-5" type="is-primary" :loading="isLoading">סרוק נתוני דוחות</b-button>
    </div>
</template>

<script>
import vSelect from "vue-select";
import FieldInlineSelect from "@/components/Fields/FieldInlineSelect.vue";
import MunicipalitiesService from "@/services/MunicipalitiesService";
import "vue-select/src/scss/vue-select.scss";
import ProfileService from "@/services/ProfileService";
import ReportService from "@/services/ReportService";

export default {
    name: "AdminCrawlReports",
    components: {
        vSelect,
        FieldInlineSelect
    },
    data() {
        return {
            users: [],
            profiles: [],
            reportsOptions: [{ Text: 'הכל', Value: false, field: "unpaidOnly" }, { Text: 'דוחות שלא שולמו', Value: true, field: "unpaidOnly" }, { Text: 'דוחות ששולמו ללא תאריך תשלום', Value: true, field: "paidWithoutPaymentDate" }, { Text: 'דוחות שאושרו לתשלום ועדיין פתוחים', Value: true, field: "approvedOnly" }],
            crawlersOptions: [],
            searchOptions: [{ Text: 'ת. אימות', Value: 0, from: "fromVerificationDate", to: "toVerificationDate" }, { Text: 'ת. קבלת הדוח', Value: 1, from: "fromDateTaken", to: "toDateTaken" }, { Text: 'ת. קליטת הדוח', Value: 2, from: "fromCreateDate", to: "toCreateDate" }],
            carOwners: null,
            isManaged: false,
            isType: false,
            isField: false,
            isLoading: false,
            reportsTypes: { Text: 'דוחות שלא שולמו', Value: true, field: "unpaidOnly" },
            crawlersTypes: { Text: 'הכל', Value: 'null' },
            searchTypes: { Text: 'ת. אימות', Value: 0, from: "fromVerificationDate", to: "toVerificationDate" },
            city: null,
            reportNumber: null,
            fromDate: null,
            toDate: null,
            isScreenshot: false

        };
    },
    created() {
        this.getProfiles(this.isManaged);
    },
    methods: {
        importData() {
            this.$buefy.dialog.confirm({
                title: "סריקת נתוני דוחות",
                message: "האם להתחיל את הסריקה?",
                confirmText: "כן",
                cancelText: "לא",
                type: "is-primary",
                closeOnConfirm: true,
                hasIcon: true,
                onConfirm: (a, instance) => {
                    this.isLoading = true;
                    ReportService.scanReports({
                        crawlerType: this.crawlersTypes?.Value,
                        profileIds: this.profiles.map((i) => i.Value),
                        reportNumber: this.reportNumber,
                        getScreenshots: this.isScreenshot,
                        municipalityId: this.city,
                        [this.reportsTypes.field]: this.reportsTypes?.Value,
                        [this.searchTypes.from]: this.fromDate,
                        [this.searchTypes.to]: this.toDate
                    })
                        .then(() => {
                            this.$buefy.toast.open({
                                type: "is-success",
                                message: "הפעולה בוצעה בהצלחה!",
                                duration: 4000,
                            });
                        })
                        .catch(() => {
                            this.$buefy.toast.open({
                                type: "is-danger",
                                message: "קרתה תקלה. לא ניתן לבצע את הפעולה!",
                                duration: 8000,
                            });
                        })
                        .finally(() => {
                            this.isLoading = false;
                            instance.close();
                        });
                },
                onCancel: (a, instance) => {
                    console.log(instance);
                    this.isLoading = false;
                }
            });
        },
        getMunicipalities() {
            return MunicipalitiesService.get;
        },
        onChange() { },
        getProfiles(isManaged) {
            ProfileService.allMapped(isManaged).then((r) => {
                this.users = r;
            });
            ProfileService.getCrawlerTypes().then((r) => {
                this.crawlersOptions = r;
            });
        },
    },
    watch: {
        isManaged(value) {
            this.getProfiles(value);
        },
        isType(value) {
            this.getProfiles(value);
        },
        isField(value) {
            this.getProfiles(value);
        },
    },
};
</script>

<style lang="scss" scoped>
.AdminLocationDataScan__View__Form {
    display: flex;
    gap: 25px;
}

.dates {
    display: flex;
    gap: 25px;
}

.AdminLocationDataScan__View {
    padding: 20px;
}

h1 {
    font-size: 25px;
    padding: 5px 0 20px 0;
}

.field {
    width: 400px;
}

::v-deep .switch {
    direction: ltr;
}

::v-deep .InlineField__Component {
    label {
        padding: 0 0 4px 0;
        border: 1px solid rgba(60, 60, 60, 0.26);
        border-radius: 4px;
    }
}
</style>
