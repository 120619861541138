var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Reports__Import__View" }, [
    _c("h1", [_vm._v("ייבוא דוחות")]),
    _c("div", { staticClass: "Reports__Import__View__Steps" }, [
      _c(
        "section",
        [
          _c(
            "b-steps",
            {
              scopedSlots: _vm._u([
                {
                  key: "navigation",
                  fn: function(ref) {
                    var previous = ref.previous
                    var next = ref.next
                    return [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.activeStep !== 6,
                              expression: "activeStep !== 6"
                            }
                          ],
                          staticClass:
                            "TollRoads__Import__View__Steps__Navigations"
                        },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                outlined: "",
                                type: "is-primary",
                                icon: ""
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.onPrevious(previous)
                                }
                              }
                            },
                            [_vm._v(" חזור ")]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: {
                                outlined: "",
                                type: "is-primary",
                                icon: ""
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.onNext(next)
                                }
                              }
                            },
                            [_vm._v(" המשך ")]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.activeStep,
                callback: function($$v) {
                  _vm.activeStep = $$v
                },
                expression: "activeStep"
              }
            },
            [
              _c("b-step-item", { attrs: { label: "בחירת קובץ" } }, [
                _c(
                  "div",
                  { staticClass: "ChooseFile__Step" },
                  [
                    _c(
                      "b-field",
                      { attrs: { label: "בחר קובץ לייבוא" } },
                      [
                        _c(
                          "b-field",
                          {
                            staticClass: "file is-primary",
                            class: { "has-name": !!_vm.file }
                          },
                          [
                            _c(
                              "b-upload",
                              {
                                staticClass: "file-label",
                                attrs: { rounded: "" },
                                model: {
                                  value: _vm.file,
                                  callback: function($$v) {
                                    _vm.file = $$v
                                  },
                                  expression: "file"
                                }
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "file-cta" },
                                  [
                                    _c("b-icon", {
                                      staticClass: "file-icon",
                                      attrs: { icon: "upload" }
                                    }),
                                    _c("span", { staticClass: "file-label" }, [
                                      _vm._v("בחר קובץ...")
                                    ])
                                  ],
                                  1
                                ),
                                _vm.file
                                  ? _c("span", { staticClass: "file-name" }, [
                                      _vm._v(" " + _vm._s(_vm.file.name) + " ")
                                    ])
                                  : _vm._e()
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c(
                "b-step-item",
                { attrs: { clickable: false, label: "הבאת מידע" } },
                [
                  _c(
                    "div",
                    { staticClass: "Progress__Step" },
                    [
                      _c("b-progress", {
                        attrs: {
                          type: "is-primary",
                          value: _vm.progress,
                          "show-value": "",
                          format: "percent"
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _c("b-step-item", { attrs: { label: "מיפוי שדות" } }, [
                _c(
                  "div",
                  { staticClass: "starting-row" },
                  [
                    _c(
                      "b-field",
                      { attrs: { label: "ייבא נתונים החל משורה" } },
                      [
                        _c("b-numberinput", {
                          attrs: { min: "0" },
                          model: {
                            value: _vm.startingRow,
                            callback: function($$v) {
                              _vm.startingRow = $$v
                            },
                            expression: "startingRow"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "table-scrollable" },
                  [
                    _vm.headers.length
                      ? _c("table-selectable", {
                          ref: "table",
                          attrs: {
                            "show-row-number": true,
                            "starting-row": _vm.startingRow,
                            headers: _vm.headers,
                            data: _vm.table
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _c("b-step-item", { attrs: { label: "הגדרות" } }, [
                _c(
                  "div",
                  { staticClass: "settings-step" },
                  [
                    _c(
                      "b-field",
                      { attrs: { label: "הערה" } },
                      [
                        _c("b-input", {
                          model: {
                            value: _vm.comment,
                            callback: function($$v) {
                              _vm.comment = $$v
                            },
                            expression: "comment"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-field",
                      { attrs: { label: "תאריך קובע" } },
                      [
                        _c("b-datepicker", {
                          staticClass: "datetime",
                          attrs: {
                            placeholder: "לחץ כדי לבחור",
                            "icon-prev": "chevron-right",
                            "icon-next": "chevron-left",
                            icon: "calendar-today",
                            locale: "en-GB",
                            "day-names": ["א", "ב", "ג", "ד", "ה", "ו", "ש"],
                            "month-names": [
                              "ינואר",
                              "פברואר",
                              "מרץ",
                              "אפריל",
                              "מאי",
                              "יוני",
                              "יולי",
                              "אוגוסט",
                              "ספטמבר",
                              "אוקטובר",
                              "נובמבר",
                              "דצמבר"
                            ],
                            "close-on-click": "",
                            editable: "",
                            "append-to-body": ""
                          },
                          model: {
                            value: _vm.date,
                            callback: function($$v) {
                              _vm.date = $$v
                            },
                            expression: "date"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-field",
                      { attrs: { label: "ייבוא ראשוני" } },
                      [
                        _c(
                          "b-switch",
                          {
                            model: {
                              value: _vm.isFirstTime,
                              callback: function($$v) {
                                _vm.isFirstTime = $$v
                              },
                              expression: "isFirstTime"
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.isFirstTime ? "כן" : "לא") + " "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-field",
                      { attrs: { label: "בקש תצלום מסך לדוח" } },
                      [
                        _c(
                          "b-switch",
                          {
                            model: {
                              value: _vm.getScreenShot,
                              callback: function($$v) {
                                _vm.getScreenShot = $$v
                              },
                              expression: "getScreenShot"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.getScreenShot ? "כן" : "לא") +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c(
                "b-step-item",
                { attrs: { label: "בחירת פרופיל" } },
                [
                  _c("div", { staticClass: "is-flex" }, [
                    _c(
                      "div",
                      { staticClass: "Field" },
                      [
                        _c(
                          "b-field",
                          { attrs: { label: "חפש" } },
                          [
                            _c("b-input", {
                              model: {
                                value: _vm.profileTerm,
                                callback: function($$v) {
                                  _vm.profileTerm = $$v
                                },
                                expression: "profileTerm"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "Field" },
                      [
                        _c(
                          "b-field",
                          { attrs: { label: "הצג פרופילים מנוהלים" } },
                          [
                            _c(
                              "b-switch",
                              {
                                staticClass: "ltr pt-2",
                                model: {
                                  value: _vm.isManaged,
                                  callback: function($$v) {
                                    _vm.isManaged = $$v
                                  },
                                  expression: "isManaged"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.isManaged ? "כן" : "לא") +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c(
                    "b-table",
                    {
                      attrs: {
                        paginated: "",
                        "sticky-header": true,
                        striped: true,
                        data: _vm.users,
                        "pagination-simple": true,
                        selected: _vm.selectedProfile,
                        hoverable: true,
                        "per-page": "30",
                        loading: _vm.isLoadingProfiles,
                        "default-sort-direction": "asc",
                        "default-sort": "Id",
                        "mobile-cards": true
                      },
                      on: {
                        "update:selected": function($event) {
                          _vm.selectedProfile = $event
                        },
                        click: _vm.onProfileClick
                      }
                    },
                    [
                      _c("b-table-column", {
                        attrs: {
                          field: "Id",
                          label: "מזהה",
                          centered: "",
                          sortable: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(props) {
                              return [_vm._v(" " + _vm._s(props.row.Id) + " ")]
                            }
                          }
                        ])
                      }),
                      _c("b-table-column", {
                        attrs: { label: "ח.פ/ת.ז", centered: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(props) {
                              return [
                                _vm._v(" " + _vm._s(props.row.IdNumber) + " ")
                              ]
                            }
                          }
                        ])
                      }),
                      _c("b-table-column", {
                        attrs: { field: "Name", label: "שם", sortable: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(props) {
                              return [
                                _vm._v(" " + _vm._s(props.row.Name) + " ")
                              ]
                            }
                          }
                        ])
                      }),
                      _c("b-table-column", {
                        attrs: { label: "כתובת" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(props) {
                              return [
                                _vm._v(
                                  " " + _vm._s(_vm.getAddress(props.row)) + " "
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("b-table-column", {
                        attrs: { label: "מיקוד" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(props) {
                              return [
                                _vm._v(" " + _vm._s(props.row.ZipCode) + " ")
                              ]
                            }
                          }
                        ])
                      }),
                      _c("b-table-column", {
                        attrs: {
                          field: "IsLeasingCompany",
                          label: "חברת ליסינג",
                          centered: "",
                          sortable: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(props) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      props.row.IsLeasingCompany ? "כן" : "לא"
                                    ) +
                                    " "
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-step-item",
                { attrs: { label: "מייבא נתונים", visible: _vm.isImporting } },
                [
                  _c(
                    "div",
                    { staticClass: "importing-step" },
                    [
                      _c("b-loading", {
                        attrs: { "is-full-page": false, "can-cancel": false },
                        model: {
                          value: _vm.isImporting,
                          callback: function($$v) {
                            _vm.isImporting = $$v
                          },
                          expression: "isImporting"
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _c(
                "b-step-item",
                { staticClass: "table-item", attrs: { label: "סטטוס" } },
                [
                  _c("div", { staticClass: "status-step" }, [
                    _c("div", { staticClass: "results-reports" }, [
                      this.succeeded
                        ? _c("p", [
                            _vm._v("דוחות שהתקבלו: " + _vm._s(_vm.succeeded))
                          ])
                        : _vm._e(),
                      this.notSucceeded
                        ? _c("p", [
                            _vm._v(
                              "דוחות שלא התקבלו: " + _vm._s(_vm.notSucceeded)
                            )
                          ])
                        : _vm._e()
                    ]),
                    _c(
                      "div",
                      { staticClass: "is-flex is-justify-content-flex-end" },
                      [
                        _c(
                          "b-tooltip",
                          { attrs: { label: "ייצוא לאקסל" } },
                          [
                            _c(
                              "b-button",
                              { on: { click: _vm.exportToExcel } },
                              [
                                _c("b-icon", {
                                  attrs: { icon: "file-excel-outline" }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("table", { staticClass: "table" }, [
                      _c("thead", [
                        _c(
                          "tr",
                          _vm._l(_vm.results.headers, function(header, index) {
                            return _c("th", { key: index }, [
                              _vm._v(" " + _vm._s(header) + " ")
                            ])
                          }),
                          0
                        )
                      ]),
                      _c(
                        "tbody",
                        _vm._l(_vm.results.rows, function(row, rowIndex) {
                          return _c(
                            "tr",
                            {
                              key: rowIndex,
                              class: { "has-error": !row.Succeeded }
                            },
                            _vm._l(row.data, function(value, valueIndex) {
                              return _c("td", { key: valueIndex }, [
                                _vm._v(" " + _vm._s(value) + " ")
                              ])
                            }),
                            0
                          )
                        }),
                        0
                      )
                    ])
                  ])
                ]
              ),
              _vm.activeStep === 6
                ? [
                    _c(
                      "div",
                      { staticClass: "mt-5" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { type: "is-primary" },
                            on: { click: _vm.reset }
                          },
                          [_vm._v("התחל ייבוא חדש")]
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }