<template>
    <div class="Reports__View">
        <EntityListLayout :tabs="tabs" ref="entity" @onNewEntitySave="onNewEntitySave">
            <template #title>דוחות לא מנוהלים</template>
            <template v-slot:counter="slotProps">
                סה"כ <b>{{ getTotalRecords(slotProps) }} </b>
            </template>
        </EntityListLayout>
    </div>
</template>
<script>
import ReportService from "@/services/ReportService";
import ProfileService from "@/services/ProfileService";
import EntityListLayout from "@/components/EntityListLayout.vue";
import AdminUnmanagedReportEntity from "@/views/AdminUnmanagedReportEntity.vue";
import TableActions from "@/services/TableActionsService";
import FieldService from "@/services/FieldService";
import VehicleService from "@/services/VehicleService";
import FilterService from "@/services/FilterService";
import NewReportEntity from "@/components/Entities/NewReportEntity.vue";
import { saveAs } from "file-saver";

export default {
    name: "AdminSearchReports",
    data() {
        return {
            tabs: [],
        };
    },
    components: {
        EntityListLayout,
    },
    computed: {
        isAdmin() {
            return this.$store.state.auth?.user?.claims?.isAdmin === "True";
        },
        tabName() {
            return this.selectedTab && this.selectedTab.title;
        },
        excelMimeType() {
            return "application/vnd.ms-excel";
        },
        isLeasingCompany() {
            return this.$store.state.profile?.IsLeasingCompany;
        },
        isReady() {
            return this.isLeasingCompany !== null;
        },
    },
    created() {
        this.create();
    },
    methods: {
        create() {
            const reportsFields = FieldService.list("reports");
            const reportsfilters = FilterService.list();

            const sideViewEntity = {
                component: AdminUnmanagedReportEntity,
                preview: true,
                readOnly: false,
                title: "פרטי דוח",
                recordUrl: "admin/reports",
            };

            // const excelExport = [
            //     {
            //         title: "יצוא טבלה",
            //         action: this.export,
            //     },
            // ];
            const fields = [
                { ...reportsFields.Address, checked: false },
                { ...reportsFields.Alerts, checked: true },
                { ...reportsFields.ApprovalDate, checked: false },
                { ...reportsFields.ClientComment, checked: false },
                { ...reportsFields.CreateDate, checked: false },
                { ...reportsFields.CurrentAmount, checked: true },
                { ...reportsFields.DateTaken, checked: true },
                { ...reportsFields.Driver, checked: false },
                { ...reportsFields.DriverAmount, checked: false },
                { ...reportsFields.DriverTransferCompleteDate, checked: false },
                { ...reportsFields.DriverTransferError, checked: false },
                { ...reportsFields.DriverTransferPdfUrl, checked: true },
                { ...reportsFields.DriverTransferSignedDate, checked: false },
                { ...reportsFields.DriverTransferStatus, checked: false },
                { ...reportsFields.DriverTransferredDate, checked: false },
                { ...reportsFields.HasScreenshot, checked: false },
                { ...reportsFields.IsPaid, checked: false },
                { ...reportsFields.IsPaidByDriver, checked: false },
                { ...reportsFields.LastPayDate, checked: true },
                { ...reportsFields.ManagerComment, checked: false },
                { ...reportsFields.MuniIdNumber, checked: false },
                { ...reportsFields.MuniReferenceNumber, checked: false },
                { ...reportsFields.Municipality, checked: true },
                { ...reportsFields.OriginalAmount, checked: true },
                // { ...reportsFields.OwnerName, checked: false }, // TransferredFromName
                { ...reportsFields.PartialPaidAmount, checked: false },
                { ...reportsFields.PaymentDate, checked: false },
                { ...reportsFields.PaymentReferenceNumber, checked: false },
                { ...reportsFields.PaymentUrl, checked: true },
                {
                    ...reportsFields.VehiclePlateNumber,
                    checked: true,
                    onClick: this.onPlateNumberClick,
                },
                // { ...reportsFields.Profile, checked: true }, // TransferredTo
                { ...reportsFields.Reason, checked: false },
                { ...reportsFields.RelevantLaw, checked: false },
                { ...reportsFields.ReportCause, checked: false },
                { ...reportsFields.ReportNumber, checked: true, isAdmin: true },
                { ...reportsFields.Status, checked: true },
                { ...reportsFields.TransferCompleteDate, checked: false },
                { ...reportsFields.TransferError, checked: false },
                { ...reportsFields.TransferPdfUrl, checked: true },
                { ...reportsFields.TransferSignedDate, checked: false },
                { ...reportsFields.TransferStatus, checked: false },
                { ...reportsFields.TransferredDate, checked: false },
                { ...reportsFields.VehicleType, checked: true },
                { ...reportsFields.VerificationDate, checked: false },
            ];

            const reportFields = [...fields, { ...reportsFields.OwnerName, checked: true }, { ...reportsFields.ProfileName, checked: true }];

            this.tabs = [
                {
                    id: "AllUnmanagedReportsAdmin",
                    title: "כל הדוחות הלא מנוהלים",
                    newEntityComponent: NewReportEntity,
                    getDataAsync: this.getAllReports,
                    perPage: 20,
                    fields: reportFields,
                    filters: [
                        { ...reportsfilters.IsPaid },
                        { ...reportsfilters.ReportStatus },
                        { ...reportsfilters.NotReportStatus },
                        { ...reportsfilters.Reason },
                        { ...reportsfilters.Alerts },
                        {
                            ...reportsfilters.Profile,
                            OptionsAsync: ProfileService.allMapped,
                            isTransferOnly: false
                        },
                        { ...reportsfilters.Driver },
                        {
                            ...reportsfilters.VehicleOwner,
                            OptionsAsync: ProfileService.allMapped,
                        },
                        { ...reportsfilters.TransferStatusWNotTransferred },
                        {
                            ...reportsfilters.DriverTransferStatusWNotTransferred,
                            Display: "סטטוס הסבת נהג",
                            Query: "DriverTransferStatus",
                            Tooltip: 'סטטוס ההסבה של נהג ע"פ המערכת',
                        },
                        { ...reportsfilters.ReportNumber },
                        { ...reportsfilters.PostOfficeReference },
                        {
                            ...reportsfilters.PlateNumber,
                            selected: this.$route.query.PlateNumber,
                            OptionsAsync: (term) =>
                                VehicleService.searchAdminPlateNumber(term).then((r) => {
                                    if (!r.data.length) return [{ Text: term, Value: term }];
                                    return r.data.map((s) => ({ Text: s.Name, Value: s.Name }));
                                }
                                ),
                        },
                        { ...reportsfilters.MuniIdNumber },
                        { ...reportsfilters.Municipality },
                        {
                            ...reportsfilters.DateTypes,
                            selected: FilterService.DateSearchTypes.DateTaken,
                            Options: this.isLeasingCompany
                                ? reportsfilters.DateTypes.Options
                                : [
                                    { ...FilterService.DateSearchTypes.DateTaken },
                                    { ...FilterService.DateSearchTypes.LastPayDate },
                                    { ...FilterService.DateSearchTypes.PaymentDate },
                                    { ...FilterService.DateSearchTypes.ApprovalDate },
                                    { ...FilterService.DateSearchTypes.VerificationDate },
                                    { ...FilterService.DateSearchTypes.CreateDate },
                                    {
                                        ...FilterService.DateSearchTypes
                                            .FirstDocumentTransferCompleteDate,
                                    },
                                    {
                                        ...FilterService.DateSearchTypes
                                            .FirstDocumentTransferDriverCompleteDate,
                                    },
                                ],
                        },
                    ],
                    sortField: "LastPayDate",
                    sortOrder: "desc",
                    checkableRows: true,
                    excelExport: [
                        {
                            title: "יצוא טבלה",
                            action: ({ loading, query }) => {
                                query.IsManaged = false;
                                query.ResultType = '';
                                this.export({ loading, query });
                            },
                        },
                    ],
                    fastSearch: true,
                    fastSearchOptions: {
                        propName: "ReportNumber",
                        label: "חיפוש לפי מספר דוח",
                    },
                    sideViewEntity,
                    tableActions: [
                        TableActions.markAsPaid,
                        TableActions.driverAssign,
                        // {
                        //   ...TableActions.changeTransferStatusCompany,
                        //   Label: "שינוי סטטוס הסבת חברה TEST",
                        // },
                        {
                            ...TableActions.changeTransferStatusDriver,
                            Label: "שינוי סטטוס הסבת נהג",
                        },
                        TableActions.changeReportStatus,
                        TableActions.reportsDetailsScanning,
                        TableActions.reportsAlertsGenerator,
                    ],
                    isAdminArea: true,
                },
            ];
        },
        async onPlateNumberClick(context) {
            context.default();
        },
        showNewEntity() {
            this.$refs.entity.newEntityMenu();
        },
        onNewEntitySave() {
            this.$refs.entity.onNewEntityCancel();
        },
        export({ loading, query }) {
            loading(true);
            ReportService.exportXslAdmin(query)
                .then((r) => {
                    this.saveFile(r.data, "יצוא דוחות ממערכת Betterway");
                })
                .finally(() => {
                    loading(false);
                });
        },
        exportByMunic({ loading, query }) {
            loading(true);
            ReportService.exportXsl(query, "Municipalities")
                .then((r) => {
                    this.saveFile(r.data, "יצוא דוחות ממערכת Betterway מקובץ לפי עיריות");
                })
                .catch(() => { })
                .finally(() => {
                    loading(false);
                });
        },
        exportByClient({ loading, query }) {
            loading(true);
            ReportService.exportXsl(query, "Profiles")
                .then((r) => {
                    this.saveFile(r.data, "יצוא דוחות ממערכת Betterway מקובץ לפי לקוחות");
                })
                .catch(() => { })
                .finally(() => {
                    loading(false);
                });
        },
        getAllReports({ cancelToken, ...rest }) {
            return ReportService.adminSearch(
                {
                    ...rest,
                    IsManaged: false
                },
                cancelToken.token
            );
        },
        getAllTransfers({ cancelToken, ...rest }) {
            return ReportService.adminSearchTransfers(
                {
                    ...rest,
                },
                cancelToken.token
            );
        },
        getPayments({ cancelToken }) {
            return ReportService.getPayments(cancelToken.token).then((r) => ({
                data: {
                    TotalResults: r.data.length,
                    Items: r.data,
                },
            }));
        },
        getTotalRecords(slotProps) {
            return slotProps.tab && slotProps.tab.total && slotProps.tab.total;
        },
        saveFile(data, filename) {
            const blob = new Blob([data], { type: this.excelMimeType });
            saveAs(blob, `${filename}.xlsx`);
        },
        checkFields(fields, idxFieldToCheck) {
            const newFields = [...fields];
            newFields.splice(idxFieldToCheck, 1, {
                ...newFields[idxFieldToCheck],
                checked: true,
            });
            return newFields;
        },
        displayFields(fields, fieldsDisplayTrueArr, fieldsDisplayFalseArr) {
            const newFields = [...fields];
            newFields.forEach((field, idx) => {
                if (fieldsDisplayTrueArr.includes(field.Display)) {
                    newFields.splice(idx, 1, {
                        ...newFields[idx],
                        checked: true,
                    });
                }
                if (fieldsDisplayFalseArr.includes(field.Display)) {
                    newFields.splice(idx, 1, {
                        ...newFields[idx],
                        checked: false,
                    });
                }
            });
            return newFields;
        },
    },
    watch: {
        isReady(value) {
            if (value) {
                this.create();
            }
        },
    },
};
</script>
<style scoped>
.Reports__View {
    height: 100%;
}
</style>
