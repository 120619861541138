var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "DialogAddLaw__Component" }, [
    _c("div", { staticClass: "modal-card" }, [
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v("מקבץ חקיקה חדש")
        ]),
        _c("button", {
          staticClass: "delete",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        })
      ]),
      _c("section", { staticClass: "modal-card-body" }, [
        _c(
          "div",
          { staticClass: "DialogAddLaw__Component__Loading" },
          [
            _c("FieldInlineText", {
              attrs: { label: "שם" },
              on: { onChange: _vm.onChange },
              model: {
                value: _vm.model.name,
                callback: function($$v) {
                  _vm.$set(_vm.model, "name", $$v)
                },
                expression: "model.name"
              }
            }),
            _c(
              "div",
              [
                _c(
                  "b-checkbox",
                  {
                    staticClass: "checkbox",
                    staticStyle: { gap: "10px" },
                    attrs: { label: "", type: "is-link is-light" },
                    model: {
                      value: _vm.model.displaySeperateLawText,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "displaySeperateLawText", $$v)
                      },
                      expression: "model.displaySeperateLawText"
                    }
                  },
                  [_vm._v(" מקבץ מסוג דרופדאון ")]
                )
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "b-checkbox",
                  {
                    staticClass: "checkbox",
                    staticStyle: { gap: "10px" },
                    attrs: { label: "", type: "is-link is-light" },
                    model: {
                      value: _vm.model.isAcademy,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "isAcademy", $$v)
                      },
                      expression: "model.isAcademy"
                    }
                  },
                  [_vm._v(" אקדמיה ")]
                )
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "b-checkbox",
                  {
                    staticClass: "checkbox",
                    staticStyle: { gap: "10px" },
                    attrs: { label: "", type: "is-link is-light" },
                    model: {
                      value: _vm.model.isActive,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "isActive", $$v)
                      },
                      expression: "model.isActive"
                    }
                  },
                  [_vm._v(" פעיל ")]
                )
              ],
              1
            ),
            _c("FieldInlineSelect", {
              attrs: {
                label: "בחר חוק מהרשימה",
                filterable: true,
                clearable: true,
                reduce: function(item) {
                  return item
                },
                optionsAsync: _vm.getLaws
              },
              on: { onChange: _vm.searchLawClauses },
              model: {
                value: _vm.chosenLaw,
                callback: function($$v) {
                  _vm.chosenLaw = $$v
                },
                expression: "chosenLaw"
              }
            }),
            _vm.chosenLaw
              ? _c("div", { staticClass: "new-law" }, [
                  _c("div", { staticClass: "item" }, [
                    _c("p", [_vm._v(_vm._s(_vm.chosenLaw.Text))]),
                    _c(
                      "div",
                      {
                        staticClass: "add",
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function($event) {
                            return _vm.addVerdictItem()
                          }
                        }
                      },
                      [_c("b-icon", { attrs: { pack: "fa", icon: "plus" } })],
                      1
                    )
                  ])
                ])
              : _vm._e(),
            _vm.clauses.length
              ? _c(
                  "div",
                  { staticClass: "clauses" },
                  _vm._l(_vm.clauses, function(clause) {
                    return _c(
                      "div",
                      {
                        key: clause.id,
                        staticClass: "clause",
                        class: { clicked: _vm.clicked.includes(clause.id) },
                        on: {
                          click: function($event) {
                            return _vm.addClauseToLaw(clause)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(clause.name) + " ")]
                    )
                  }),
                  0
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _c(
        "footer",
        { staticClass: "modal-card-foot" },
        [
          _c("b-button", {
            attrs: {
              loading: _vm.isLoading,
              label: "שמור",
              type: "is-primary"
            },
            on: { click: _vm.save }
          }),
          _c("b-button", {
            attrs: { disabled: _vm.isLoading, label: "סגור" },
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }