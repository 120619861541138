var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "unitReplication" } }, [
    _c("h1", [_vm._v("שכפול יחידת לימוד")]),
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.onSubmit($event)
          }
        }
      },
      [
        _c("h3", [_vm._v("תחילה יש לבחור את יחידת הלימוד שתשוכפל")]),
        _c(
          "div",
          { staticClass: "loading" },
          [
            _c("b-loading", {
              attrs: { "is-full-page": true, "can-cancel": false },
              model: {
                value: _vm.loading,
                callback: function($$v) {
                  _vm.loading = $$v
                },
                expression: "loading"
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "input-section" }, [
          _c("label", { attrs: { for: "from-course" } }, [_vm._v("מקורס")]),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.model.fromCourseId,
                  expression: "model.fromCourseId"
                }
              ],
              on: {
                change: [
                  function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.model,
                      "fromCourseId",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                  function($event) {
                    return _vm.searchUnit(_vm.model.fromCourseId)
                  }
                ]
              }
            },
            _vm._l(_vm.courses, function(course) {
              return _c("option", { domProps: { value: course.id } }, [
                _vm._v(_vm._s(course.name))
              ])
            }),
            0
          )
        ]),
        _c(
          "div",
          {
            staticClass: "input-section",
            staticStyle: { "margin-bottom": "8px" }
          },
          [
            _c("label", { attrs: { for: "to-unit" } }, [
              _vm._v("מיחידת לימוד")
            ]),
            _vm.units
              ? _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.lessonId,
                        expression: "model.lessonId"
                      }
                    ],
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.model,
                          "lessonId",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  _vm._l(_vm.units.lessons, function(unit) {
                    return _c("option", { domProps: { value: unit.id } }, [
                      _vm._v(_vm._s(unit.name))
                    ])
                  }),
                  0
                )
              : _c("select", { class: [!_vm.units && "disabled"] }, [
                  _c("option", { attrs: { value: "בחר קודם קורס" } }, [
                    _vm._v("בחר קודם קורס")
                  ])
                ])
          ]
        ),
        _c(
          "div",
          {
            staticClass: "input-section",
            staticStyle: { "margin-bottom": "5px" }
          },
          [
            _c("label", { attrs: { for: "to-course" } }, [_vm._v("לקורס")]),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.toCourseId,
                    expression: "model.toCourseId"
                  }
                ],
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.model,
                      "toCourseId",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  }
                }
              },
              _vm._l(_vm.courses, function(course) {
                return _c("option", { domProps: { value: course.id } }, [
                  _vm._v(_vm._s(course.name))
                ])
              }),
              0
            )
          ]
        ),
        _c(
          "div",
          { staticClass: "input-section" },
          [
            _c(
              "b-button",
              {
                attrs: {
                  loading: _vm.loading,
                  disabled: !_vm.model.fromCourseId,
                  type: "submit"
                },
                on: { click: _vm.onSubmit }
              },
              [_vm._v("שכפול יחידת לימוד")]
            )
          ],
          1
        )
      ]
    ),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pay-attention" }, [
      _c("p", [_vm._v("שימו לב:")]),
      _c("p", [_vm._v("1. יחידת הלימוד תשוכפל לקורס הרצוי.")]),
      _c("p", [_vm._v("2. יחידת הלימוד המשוכפלת תהיה בסטטוס 0.")]),
      _c("p", [_vm._v("3. ניתן לשכפל יחידת לימוד רק עם הרשאות מנהל.")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }