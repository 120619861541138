<template>
  <div class="Quotes">
    <div class="Quotes__Header">
      <!-- <div class="right">
        <h1>ציטוטים</h1>
      </div> -->
    </div>
    <div class="loading">
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
    </div>
    <div class="Quotes__Content">
      <div class="right">
        <div class="flex space-between items-center">
          <b-field style="width: 100%" label="חפש פסק דין">
            <b-input v-on:keydown.native.enter="searchVerdict" v-model="documentId" style="max-width: 30%;"
              type="number"></b-input>
            <b-button class="close" @click="searchVerdict">חפש</b-button>
            <v-select v-if="quoteHrefs.length" style="height: 40px; max-width: 45%; width: 100%;" @input="nextVerdict"
              dir="rtl" label="Text" v-model="quoteHref" :options="quoteHrefs" :clearable="false" :multiple="false"
              :close-on-select="true" :placeholder="'ההפניה הבאה'">
              <div slot="no-options">לא נמצאו ערכים דומים</div>
            </v-select>
          </b-field>
          <div v-if="verdictFile" class="Quotes__Footer">
            <b-button class="close" :disabled="rightHtml"
              style="text-align: center; justify-content: center; height: 45px" @click="connectRightQuote">סמן
              ציטוט</b-button>
            <!-- <b-button :disabled="!rightHtml" style="text-align: center; justify-content: center;"
        @click="cancelSelection('right')">בטל ציטוט</b-button> -->
          </div>
        </div>
        <div v-if="verdictFile" class="right__content">
          <div style="position: relative;" id="quoter-html" v-html="verdictFile"></div>
        </div>
      </div>
      <div class="left">
        <div style="gap: 10px" class="flex space-between items-center">
          <b-field style="width: 100%" v-if="quotes.length" label="פסקי דין מקושרים">
            <v-select style="height: 40px; max-width: 100%;" @input="onChange" dir="rtl" label="Text"
              v-model="selectedQuote" :options="quotes" :clearable="false" :multiple="false" :searchable="true"
              :close-on-select="true" :placeholder="'בחר ערך מהרשימה'">
              <div slot="no-options">לא נמצאו ערכים דומים</div>
            </v-select>
          </b-field>
          <div v-if="quoteFile" class="Mark__Container">
            <b-button class="close" :disabled="!hashedQuotes"
              style="text-align: center; justify-content: center; height: 45px;" @click="connectLeftQuote">סמן
              ציטוט</b-button>
            <b-button class="show-entity-btn" @click="save" :disabled="!rightHtml || !leftHtml">שמור</b-button>
            <!-- <b-button :disabled="hashedQuotes" style="text-align: center; justify-content: center;"
              @click="cancelSelection('left')">בטל ציטוט</b-button> -->
          </div>
        </div>
        <div v-if="quoteFile" class="left__content">
          <div id="quoted-html" v-html="quoteFile"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { ToastProgrammatic as Toast } from "buefy";
// import AdminService from '@/services/AdminService';
// import FieldInlineSelect from '@/components/Fields/FieldInlineSelect.vue';
import AdminService from "../services/AdminService";
import vSelect from "vue-select";
import "vue-select/src/scss/vue-select.scss";

export default {
  components: {
    // FieldInlineSelect,
    vSelect
  },
  name: "AdminImportCreate",
  data() {
    return {
      isLoading: false,
      documentId: null,
      verdictFile: null,
      quotes: [],
      hashedQuotes: null,
      hashRequest: null,
      selectedQuote: null,
      quoteFile: null,
      selectedText: null,
      rightHtml: null,
      leftHtml: null,
      quoteHref: null,
      quoteHrefs: [],
      rightChecksums: [],
      leftChecksums: []
    };
  },
  methods: {
    searchVerdict() {
      if (this.documentId) {
        this.isLoading = true;
        AdminService.getVerdictQuotes(this.documentId).then(r => {
          this.quotes = r.data.map(q => ({ Value: q.id, Text: q.name }));
          this.getVerdict(this.documentId);
          this.quoteFile = null;
        }).catch(() => {
          this.isLoading = false;
        });
      }
    },
    nextVerdict() {
      document.getElementById(this.quoteHref.Value).scrollIntoView();
    },
    getVerdict(documentId) {
      AdminService.getVerdictFile(documentId).then(r => {
        this.verdictFile = r.data;
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      });
    },
    onChange() {
      this.isLoading = true;
      const selectedQuoteId = this.selectedQuote.Value;
      AdminService.getVerdictFiles(this.documentId, selectedQuoteId).then(r => {
        this.verdictFile = r.data.quoterDocument;
        // this.verdictFile = '<div style="padding: 20px;"><a id="1" style="position: relative" class="dror">asd</a> <a id="2" style="position: relative" class="dror">fas</a> <a id="3" style="position: relative" class="dror">ghj</a></div>';
        this.quoteFile = r.data.quotedDocument;
        this.quoteHrefs = r.data.quotes.map((q) => ({ Value: q.value, Text: q.text.length > 27 ? q.text.slice(0, 27) + '...' : q.text }));
        this.isLoading = false;
        this.insertIconsToChecksums();
      }).catch(() => {
        this.isLoading = false;
      });
    },
    insertIconsToChecksums() {
      const quoterEl = document.getElementById('quoter-html');
      const aTags = quoterEl.getElementsByClassName('marked-quote');
      setTimeout(() => {
        const arr = Array.prototype.slice.call(aTags)
        // arr.forEach((a) => {
        //   const node = document.createElement("i");
        //   // const textnode = document.createTextNode("x");
        //   a.style.position = "relative";
        //   node.style.color = "red";
        //   node.classList.add('delete');
        //   node.style.position = "absolute";
        //   node.style.bottom = "10px";
        //   // node.appendChild(textnode);
        //   // node.setAttribute('id', a.href);
        //   // node.style.left = 0;
        //   node.addEventListener("click", () => {
        //     const value = a.getAttribute('href').replace('#', "");
        //     console.log(value);
        //     this.deleteQuote(value)
        //   })
        //   a.appendChild(node);
        // })
        arr.forEach((a) => {
          let parentChecksumElement = a.closest('p');
          if (parentChecksumElement) {
            parentChecksumElement.style.position = "relative";
            const node = document.createElement("i");
            node.style.color = "red";
            node.classList.add('delete');
            node.style.position = "absolute";
            node.style.bottom = "10px";
            // node.appendChild(textnode);
            // node.setAttribute('id', a.href);
            // node.style.left = 0;
            node.addEventListener("click", () => {
              const value = a.getAttribute('href').replace('#', "");
              console.log(value);
              this.deleteQuote(value)
            })
            parentChecksumElement.appendChild(node);
          }
        })
      }, 1000);
    },
    deleteQuote(quoteId) {
      console.log(quoteId);
      this.isLoading = true;
      AdminService.deleteQuote(quoteId)
        .then(() => {
          this.$buefy.toast.open({
            type: "is-success",
            message: `ציטוט נמחק בהצלחה!`,
            duration: 4000
          });
          this.onChange();
        }).catch(() => {
          this.$buefy.toast.open({
            type: "is-danger",
            message: "מחיקת ציטוט נכשלה!",
            duration: 8000
          })
          this.isLoading = false;
        });
    },
    insertHtmlAtSelectionEnd(html, isBefore) {
      var sel, range, node, checkSum;
      if (window.getSelection) {
        sel = window.getSelection();
        if (sel.getRangeAt && sel.rangeCount) {
          range = window.getSelection().getRangeAt(0);
          if (isBefore) {
            checkSum = range.startContainer.parentElement.closest("[checksum]");
            this.selectedText = range.toString();
          } else {
            checkSum = range.endContainer.parentElement.closest("[checksum]");
            range.collapse(isBefore);
          }
          // Range.createContextualFragment() would be useful here but was
          // until recently non-standard and not supported in all browsers
          // (IE9, for one)
          var el = document.createElement("div");
          el.innerHTML = html;
          var frag = document.createDocumentFragment(),
            node,
            lastNode;
          while ((node = el.firstChild)) {
            lastNode = frag.appendChild(node);
          }
          range.insertNode(frag);
        }
      } else if (document.selection && document.selection.createRange) {
        range = document.selection.createRange();
        range.collapse(isBefore);
        range.pasteHTML(html);
      }
      return checkSum;
    },
    cancelSelection(type) {
      if (type === 'right') {
        this.$nextTick(() => {
          this.verdictFile = this.verdictFile.replace('[START]', "");
          this.verdictFile = this.verdictFile.replace('[END]', "");
        });
        console.log(this.verdictFile);
      } else {
        this.quoteFile = this.quoteFile.replace('[START]', "");
        this.quoteFile = this.quoteFile.replace('[END]', "");
        console.log(this.quoteFile);
      }
    },
    connectRightQuote() {
      var outerStartCheckSumHtml,
        outerEndCheckSumHtml,
        startIdx,
        endIdx,
        startCheckSumValue,
        endCheckSumValue,
        markedText,
        data;

      var range = window.getSelection().getRangeAt(0);
      markedText = range.cloneContents().querySelectorAll('[checksum]');
      if (markedText && markedText.length) {
        for (let index = 0; index < markedText.length; index++) {
          this.rightChecksums.push({
            Checksum: markedText[index].getAttribute('checksum')
          })
        }
      } else {
        this.rightChecksums.push({
          Checksum: range.startContainer.parentElement.closest('[checksum]').getAttribute('checksum')
        })
      }

      const startCheckSum = this.insertHtmlAtSelectionEnd("[START]", true);
      const endCheckSum = this.insertHtmlAtSelectionEnd("[END]", false);

      const anchorInChecksum = startCheckSum.getElementsByClassName('marked-quote');
      anchorInChecksum.forEach((a) => {
        console.log(a);
        a.replaceWith(a.innerText);
      })

      outerStartCheckSumHtml = startCheckSum.outerHTML.replace(/(&quot\;)/g, '"');;
      outerEndCheckSumHtml = endCheckSum.outerHTML.replace(/(&quot\;)/g, '"');;

      startIdx = outerStartCheckSumHtml.indexOf("[START]");
      endIdx = outerEndCheckSumHtml.indexOf("[END]");

      this.rightHtml = document.getElementsByClassName('right__content')[0].firstChild.outerHTML;
      console.log(this.rightHtml);

      if (outerEndCheckSumHtml.indexOf("[START]") != -1) {
        endIdx -= 7;
      }

      startCheckSumValue = startCheckSum.getAttribute("checksum");
      endCheckSumValue = endCheckSum.getAttribute("checksum");

      this.hashedQuotes = {
        rightStart: {
          checksum: startCheckSumValue,
          index: startIdx,
          selectedText: this.selectedText
        },
        rightEnd: {
          checksum: endCheckSumValue,
          index: endIdx
        }
      };
    },
    connectLeftQuote() {
      var outerStartCheckSumHtml,
        outerEndCheckSumHtml,
        startIdx,
        endIdx,
        startCheckSumValue,
        endCheckSumValue,
        markedText,
        data;

      var range = window.getSelection().getRangeAt(0);
      markedText = range.cloneContents().querySelectorAll('[checksum]');
      if (markedText && markedText.length) {
        for (let index = 0; index < markedText.length; index++) {
          this.leftChecksums.push({
            Checksum: markedText[index].getAttribute('checksum')
          })
        }
      } else {
        this.rightChecksums.push({
          Checksum: range.startContainer.parentElement.closest('[checksum]').getAttribute('checksum')
        })
      }
      
      const startCheckSum = this.insertHtmlAtSelectionEnd("[START]", true);
      const endCheckSum = this.insertHtmlAtSelectionEnd("[END]", false);

      const anchorInChecksum = startCheckSum.getElementsByTagName('mark');
      console.log(anchorInChecksum);
      anchorInChecksum.forEach((a) => {
        a.replaceWith(a.innerText);
      })

      outerStartCheckSumHtml = startCheckSum.outerHTML.replace(/(&quot\;)/g, '"');;
      outerEndCheckSumHtml = endCheckSum.outerHTML.replace(/(&quot\;)/g, '"');;

      startIdx = outerStartCheckSumHtml.indexOf("[START]");
      endIdx = outerEndCheckSumHtml.indexOf("[END]");

      this.leftHtml = document.getElementsByClassName('left__content')[0].firstChild.outerHTML;

      if (outerEndCheckSumHtml.indexOf("[START]") != -1) {
        endIdx -= 7;
      }

      startCheckSumValue = startCheckSum.getAttribute("checksum");
      endCheckSumValue = endCheckSum.getAttribute("checksum");

      this.hashedQuotes = {
        ...this.hashedQuotes,
        leftStart: {
          checksum: startCheckSumValue,
          index: startIdx,
          selectedText: this.selectedText
        },
        leftEnd: {
          checksum: endCheckSumValue,
          index: endIdx
        }
      };

      const hashRequest = {
        Quoter: {
          DocumentId: this.documentId,
          Text: this.hashedQuotes.rightStart.selectedText,
          ParagraphPositionChecksumModel: this.rightChecksums,
          StartParagraphChecksum: this.hashedQuotes.rightStart.checksum,
          StartRelativeIndex: this.hashedQuotes.rightStart.index,
          EndParagraphChecksum: this.hashedQuotes.rightEnd.checksum,
          EndRelativeIndex: this.hashedQuotes.rightEnd.index,
          HtmlString: this.rightHtml
        },
        Quoted: {
          DocumentId: this.selectedQuote.Value.toString(),
          Text: this.hashedQuotes.leftStart.selectedText,
          ParagraphPositionChecksumModel: this.leftChecksums,
          StartParagraphChecksum: this.hashedQuotes.leftStart.checksum,
          StartRelativeIndex: this.hashedQuotes.leftStart.index,
          EndParagraphChecksum: this.hashedQuotes.leftEnd.checksum,
          EndRelativeIndex: this.hashedQuotes.leftEnd.index,
          HtmlString: this.leftHtml
        }
      };
      this.hashRequest = hashRequest;
    },
    save() {
      this.isLoading = true;
      AdminService.hashQuotes(this.hashRequest)
        .then(r => {
          console.log(r.data);
          this.$buefy.toast.open({
            type: "is-success",
            message: `ציטוט קושר בהצלחה!`,
            duration: 4000
          });
          this.onChange();
          this.rightHtml = null;
        })
        .catch(e => {
          console.log(e);
          this.$buefy.toast.open({
            type: "is-danger",
            message: "קישור ציטוט נכשל!",
            duration: 8000
          });
          this.isLoading = false;
        });
    }
  },
  watch: {}
};
</script>
<style scoped lang="scss">
.flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

.Quotes {
  height: calc(100% - 50px);
  margin-top: 30px;

  .Quotes__Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 25px 0 0;

    h1 {
      font-size: 25px;
    }
  }

  .Quotes__Content {
    display: flex;
    padding: 0 20px;
    height: 99%;
    gap: 20px 40px;
    margin-left: 30px;

    .right,
    .left {
      flex: 1;
      width: 50%;
    }

    .right__content,
    .left__content {
      height: 85%;
      box-shadow: 0px 1px 4px 0px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      overflow-y: auto;
      padding: 15px;
    }
  }

  .Quotes__Footer {
    padding: 0 20px;
  }

  .Mark__Container {
    // margin: 30px 0;
    display: flex;
    justify-content: space-between;
  }
}
</style>
<style lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.halacha {
  background-color: orange !important;
}

// .marked-quote i {
//   display: none;
// }

.marked-quote:hover {
  text-decoration: underline;

  // i {
  //   display: inline-block;
  // }
}

#vs2__combobox {
  height: 40px;
  overflow: hidden;
}

.close {
  background: white;
  color: #ff7a59;
  border: none;
  border: 1px solid #ff7a59;
}

.show-entity-btn {
  color: white;
  background-color: #ff7a59;
  height: 45px;
  padding: 10px 30px;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
}
</style>
