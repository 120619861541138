var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "LessonEntityDetails__Component" },
    [
      _c("FieldInlineText", {
        attrs: { label: "שם התקציר" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.name,
          callback: function($$v) {
            _vm.$set(_vm.model, "name", $$v)
          },
          expression: "model.name"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "נושא ראשי" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.category,
          callback: function($$v) {
            _vm.$set(_vm.model, "category", $$v)
          },
          expression: "model.category"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "נושא משני" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.subCategory,
          callback: function($$v) {
            _vm.$set(_vm.model, "subCategory", $$v)
          },
          expression: "model.subCategory"
        }
      }),
      _c("b-taginput", {
        attrs: {
          ellipsis: "",
          icon: "label",
          placeholder: "הוסף סוגיה",
          "aria-close-label": "מחק סוגיה זו"
        },
        on: { input: _vm.onChange },
        model: {
          value: _vm.model.modelSubLabels,
          callback: function($$v) {
            _vm.$set(_vm.model, "modelSubLabels", $$v)
          },
          expression: "model.modelSubLabels"
        }
      }),
      _c("FieldInlineText", {
        attrs: { type: "number", label: "מספר פסק דין" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.nevoVerdictId,
          callback: function($$v) {
            _vm.$set(_vm.model, "nevoVerdictId", $$v)
          },
          expression: "model.nevoVerdictId"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }