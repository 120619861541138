<template>
    <div class="AdminAnalyticsYearly__View">
        <div class="AdminAnalyticsYearly__Title">
            <h1>דו"ח פילוח שנתי</h1>
            <p>בדוח זה ניתן לצפות בנתוני דוחות של טווח שנים. העמודות מייצגות את מספר הרכבים המנוהלים, כמו הדוחות, דוחות
                ששולמו, והסכום שיש לשלם בכל שנה.</p>
        </div>
        <div class="AdminAnalyticsYearly__Details">
            <b-field>
                <b-slider @change="getAnalytics" :tooltip="false" size="is-medium" v-model="years" :min="2013" :max="2023"
                    :step="1" ticks>
                    <template v-for="year in [2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023]">
                        <b-slider-tick :value="year" :key="year">{{ year }}</b-slider-tick>
                    </template>
                </b-slider>
            </b-field>
            <div class="bar-chart" v-if="barChartData.labels">
                <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false" />
                <BarChart :chart-data="barChartData" :options="barChartOptions" :width="800" />
            </div>
        </div>
    </div>
</template>

<script>
import "vue-select/src/scss/vue-select.scss";
import ReportService from "@/services/ReportService";
import BarChart from "@/components/Charts/Bar.vue";

export default {
    name: "AdminAnalyticsYearly",
    components: { BarChart },
    data() {
        return {
            years: [2021, 2023],
            isLoading: false,
            results: [],
            barChartData: {
                labels: [],
                datasets: []
            },
            barChartOptions: null
        };
    },
    created() {
        this.getAnalytics(this.years);
    },
    methods: {
        async getAnalytics(years) {
            this.isLoading = true;
            const maximum = [];
            const objStatistics = [
                {
                    label: 'סכום הדוחות',
                    data: [],
                    backgroundColor: 'rgba(62, 111, 90, 0.2)',
                    borderColor: [
                        'rgb(62, 111, 90)',
                    ],
                    borderWidth: 2,
                },
                {
                    label: 'סכום ששולם בפועל',
                    data: [],
                    backgroundColor: 'rgba(91, 193, 142, 0.2)',
                    borderColor: [
                        'rgb(91, 193, 142)',
                    ],
                    borderWidth: 2,
                },
                {
                    label: 'כמות דוחות',
                    data: [],
                    backgroundColor: 'rgba(230, 124, 124, 0.2)',
                    borderColor: [
                        'rgb(230, 124, 124)',
                    ],
                    borderWidth: 2,
                },
                {
                    label: 'רכבים מנוהלים',
                    data: [],
                    backgroundColor: 'rgba(116, 164, 185, 0.2)',
                    borderColor: [
                        'rgb(116, 164, 185)',
                    ],
                    borderWidth: 2,
                },
            ];

            this.years = years;
            const diff = this.years[1] - this.years[0];
            const currentYears = [];
            for (let i = 0; i <= diff; i += 1) {
                currentYears.push(this.years[0] + i);
            }

            for (let k = 0; k < currentYears.length; k += 1) {
                /* eslint-disable no-await-in-loop */
                const res = await ReportService.getYearlyStatistics(currentYears[k]);
                res.data.Year = 0;

                objStatistics[0].data.push(res.data.ReportsCount);
                objStatistics[0].backgroundColor = 'rgba(230, 124, 124, 0.2)';
                objStatistics[0].borderColor = 'rgb(230, 124, 124)';
                objStatistics[0].borderWidth = 3;

                objStatistics[1].data.push(res.data.ReportsPaidAmount);
                objStatistics[1].backgroundColor = 'rgba(91, 193, 142, 0.2)';
                objStatistics[1].borderColor = 'rgb(91, 193, 142)';
                objStatistics[1].borderWidth = 3;

                objStatistics[2].data.push(res.data.ReportsTotalAmount);
                objStatistics[2].backgroundColor = 'rgba(62, 111, 90, 0.2)';
                objStatistics[2].borderColor = 'rgb(62, 111, 90)';
                objStatistics[2].borderWidth = 3;

                objStatistics[3].data.push(res.data.ManagedVehicleCount);
                objStatistics[3].backgroundColor = 'rgba(116, 164, 185, 0.2)';
                objStatistics[3].borderColor = 'rgb(116, 164, 185)';
                objStatistics[3].borderWidth = 3;

                const arr = Object.values(res.data);
                maximum.push(Math.max(...arr));
            }

            const barChartData = {
                labels: currentYears,
                datasets: objStatistics,
            };
            const barChartOptions = {
                responsive: true,
                maintainAspectRatio: false,
                colors: ['#74a4b9', '#e67c7c', '#5bc18e', '#3e6f5a'],
                tooltips: {
                    bodyFontSize: 16,
                    titleFontSize: 16,
                    bodySpacing: 10,
                    titleSpacing: 10,
                },
                legend: {
                    position: 'bottom',
                    display: true,
                    labels: {
                        fontColor: 'rgb(0, 0, 0)',
                        boxWidth: 15,
                        fontSize: 16
                    }
                },
                scales: {
                    xAxes: [{
                        // barThickness: 20,
                    }]
                },
            };
            this.isLoading = false;
            this.barChartData = barChartData;
            this.barChartOptions = barChartOptions;
        },
    },
};
</script>

<style lang="scss" scoped>
.AdminAnalyticsYearly__View {
    padding: 20px;

    .AdminAnalyticsYearly__Title {
        // margin-bottom: 20px;
    }

    .AdminAnalyticsYearly__Details {
        margin-top: 20px;
        flex-direction: column;
        align-items: center;
        display: flex;
        justify-content: center;
    }
}

h1 {
    font-size: 25px;
    padding: 5px 0 20px 0;
}

.field {
    width: 400px;
}

.bar-chart {
    display: flex;
    justify-content: center;
    width: 1229px;
    height: 614px;
}

#bar-chart {
    height: 100% !important;
}

::v-deep .switch {
    direction: ltr;
}
</style>
