import axios from "axios";

const BASE_URL = process.env.VUE_APP_APIURL;

const postVehicles = (data, options) =>
  axios.post(`${BASE_URL}/api/ExternalUser/Vehicles`, data, options);
const postVehicle = (data, options) =>
  axios.post(`${BASE_URL}/api/ExternalUser/Vehicle`, data, options);
const postOwnedVehicles = (data, options) =>
  axios.post(`${BASE_URL}/api/ExternalUser/Vehicles/Owned`, data, options);
const postOwnedVehicle = (data, options) =>
  axios.post(`${BASE_URL}/api/ExternalUser/Vehicle/Owned`, data, options);
const postNewVehicles = (data, options) =>
  axios.post(`${BASE_URL}/api/ExternalUser/V2/Vehicles`, data, options);
const postNewVehicle = (data, options) =>
  axios.post(`${BASE_URL}/api/ExternalUser/V2/Vehicle`, data, options);

export default {
  postVehicles,
  postVehicle,
  postOwnedVehicles,
  postOwnedVehicle,
  postNewVehicle,
  postNewVehicles
};
