<template>
  <div class="AdminImportCreate__View">
    <h1>יצירת קובץ לייבוא</h1>
    <div class="AdminImportCreate__View__Form">
      <div class="field">
        <b-field label="מציג פרופילים מנוהלים">
          <b-switch v-model="isManaged">
            {{ isManaged ? 'כן' : 'לא', }}
          </b-switch>
        </b-field>
        <b-field label="בחר פרופיל">
          <v-select dir="rtl" label="Text" v-model="profiles" :options="users" :clearable="true" :multiple="true"
            :searchable="true" :close-on-select="false" :placeholder="'בחר ערך מהרשימה'">
            <div slot="no-options">לא נמצאו ערכים דומים</div>
          </v-select>
        </b-field>
        <b-button @click="importData" class="mt-5" type="is-primary" :loading="isLoading">התחל ייבוא</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/src/scss/vue-select.scss";
import ProfileService from "@/services/ProfileService";
import ReportService from "@/services/ReportService";

export default {
  name: "AdminImportPolice",
  components: {
    vSelect,
  },
  data() {
    return {
      users: [],
      profiles: [],
      isManaged: false,
      isLoading: false,
    };
  },
  created() {
    this.getProfiles(this.isManaged);
  },
  methods: {
    importData() {
      this.$buefy.dialog.confirm({
        title: "ייבוא דוחות משטרה",
        message: "האם להתחיל את הייבוא?",
        confirmText: "כן",
        cancelText: "לא",
        type: "is-primary",
        closeOnConfirm: false,
        hasIcon: true,
        onConfirm: (a, instance) => {
          this.isLoading = true;
          instance.close();
          ReportService.importPolice({ ProfileIds: this.profiles.map((i) => i.Value) })
            .then(() => {
              this.$buefy.dialog.alert({
                type: "is-success",
                message: "הפעולה בוצעה בהצלחה!",
                confirmText: 'אוקיי'
              });
              this.isLoading = false;
              instance.close();
            })
            .catch((err) => {
              const errorCode = Math.floor(err.response.status / 100);
              let msg = '';
              if (errorCode === 4) msg = 'אירעה שגיאה בתהליך, פרטים נוספים נשלחו במייל.';
              else msg = `שגיאה ${err.response.status}`;
              this.$buefy.dialog.alert({
                type: "is-danger",
                message: msg,
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                confirmText: 'אוקיי',
              });
              this.isLoading = false;
              instance.close();
            })
            .finally(() => {
              this.isLoading = false;
              instance.close();
            });
        },
      });
    },
    getProfiles(isManaged) {
      ProfileService.allMapped(isManaged).then((r) => {
        this.users = r;
      });
    },
  },
  watch: {
    isManaged(value) {
      this.getProfiles(value);
    },
  },
};
</script>

<style lang="scss" scoped>
.AdminImportCreate__View {
  padding: 20px;
}

h1 {
  font-size: 25px;
  padding: 5px 0 20px 0;
}

.field {
  width: 400px;
}

::v-deep .switch {
  direction: ltr;
}
</style>
