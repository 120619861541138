var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Municipality__Entity" },
    [
      _c("MunicipalityEntityDetails", {
        attrs: { "is-open": true, item: _vm.id, entityId: _vm.id.EntityId }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }