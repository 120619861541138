<template>
  <div class="Reports__Import__View">
    <h1>עדכון חוק/חוקים חסרים לתרגול</h1>
    <div class="Reports__Import__View__Steps">
      <div class="loading">
        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false" />
      </div>
      <div>
        <b-field label="עדכון חוקים חסרים לתרגול">
          <b-button @click="updateExercises">
            עדכון
          </b-button>
        </b-field>
      </div>
      <div>
        <b-field label="עדכון חוק לתרגול בודד">
          <input type="number" v-model="exerciseId" placeholder="מספר התרגול">
          <b-button @click="updateExercise">עדכון</b-button>
        </b-field>
      </div>
    </div>
  </div>
</template>
<script>
import { ToastProgrammatic as Toast } from "buefy";
import AdminService from '@/services/AdminService';

export default {
  components: {},
  name: 'AdminImportCreate',
  data() {
    return {
      isLoading: false,
      exerciseId: null

    };
  },
  computed: {
  },
  created() {
  },
  methods: {
    updateExercises() {
      this.isLoading = true;
      AdminService.updateExercisesLaws()
        .then(() => {
          Toast.open({
            type: "is-success",
            message: "השיוך בוצע בהצלחה!",
            duration: 8000,
          });
        }).catch(() => {
          Toast.open({
            type: "is-danger",
            message: "לא היה ניתן לבצע את הפעולה.",
            duration: 8000,
          });
        }).finally(() => {
          this.isLoading = false;
        });
    },
    updateExercise() {
      if (!this.exerciseId) return;
      this.isLoading = true;
      AdminService.updateExerciseLaws(this.exerciseId)
        .then(() => {
          Toast.open({
            type: "is-success",
            message: "השיוך בוצע בהצלחה!",
            duration: 8000,
          });
        }).catch(() => {
          Toast.open({
            type: "is-danger",
            message: "לא היה ניתן לבצע את הפעולה.",
            duration: 8000,
          });
        }).finally(() => {
          this.isLoading = false;
        });
    }
  },
  watch: {},
};
</script>
<style scoped lang="scss">
.Reports__Import__View h1 {
  font-size: 25px;
  padding: 25px 25px 0 0;
}

.Reports__Import__View__Steps {
  padding: 50px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-content: center;

  ::v-deep .step-content {
    padding: 60px 0 10px 0;
  }

  section {
    width: 100%;
  }

  .ChooseFile__Step {
    display: flex;
    flex-direction: column;
    justify-items: center;
    padding: 30px 0;
    width: 600px;
    margin: 0 auto;

    ::v-deep {

      .field,
      .file {
        text-align: center;
        justify-content: center;
      }
    }
  }

  .Progress__Step {
    width: 600px;
    padding: 30px 0;
    margin: 0 auto;
  }

  ::v-deep {

    .b-steps .steps .step-items .step-item:not(:first-child)::before,
    .b-steps .steps .step-items .step-item:only-child::before {
      right: -50%;
      left: unset;
    }

    .b-steps .steps .step-items .step-item::before,
    .b-steps .steps .step-items .step-item::after {
      background-position: left bottom;
      background: linear-gradient(to right, #dbdbdb 50%, #7957d5 50%);
      background-size: 200% 100%;
    }

    .b-steps .steps .step-items .step-item.is-active::before,
    .b-steps .steps .step-items .step-item.is-active::after {
      background-position: right bottom;
    }

    .b-steps .steps .step-items .step-item.is-previous::before,
    .b-steps .steps .step-items .step-item.is-previous::after {
      background-position: right bottom;
    }
  }

  .TollRoads__Import__View__Steps__Navigations {
    display: flex;
    justify-content: center;

    ::v-deep button {
      margin: 0 5px;
    }
  }
}

::v-deep {
  .top.level {
    flex-direction: row-reverse;
  }

  .pagination.is-simple {
    flex-direction: row-reverse;
  }

  .b-table .table-wrapper.has-sticky-header {
    height: calc(100vh - 485px);
    overflow-y: auto;
  }
}

.starting-row {
  width: 300px;
  margin-bottom: 10px;
  display: flex;
  align-items: self-end;

  .field:not(:last-child) {
    margin-bottom: 0;
  }
}

.switch {
  direction: ltr;
}

.settings-step {
  width: 500px;
}

.importing-step {
  height: 500px;
}

.status-step {

  .table {
    width: 100%;
    margin-top: 30px;

    tbody tr {
      background-color: #daffda;
    }
  }

  .has-error {
    background-color: #ffdada !important;
  }
}

::v-deep .table.is-hoverable tbody tr:not(.is-selected):hover {
  background-color: #eaf0f6 !important;
  cursor: pointer;
}

::v-deep .table.is-striped tbody tr:not(.is-selected):nth-child(even) {
  background-color: #efefef;
}
</style>
