<template>
    <div class="DialogReportPaid__Component">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">הוספת ספר לבנק</p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">
                <div class="DialogReportPaid__Component__Loading">
                    <b-field label="הזן כותרת לספר">
                        <b-input v-model="model.title"></b-input>
                    </b-field>
                    <b-field label="הזן תת כותרת לספר">
                        <b-input v-model="model.subtitle"></b-input>
                    </b-field>
                    <b-field label="הזן יוצר לספר">
                        <b-input v-model="model.author"></b-input>
                    </b-field>
                    <b-field label="הזן קישור לספר">
                        <b-input v-model="model.link"></b-input>
                    </b-field>
                    <b-field label="הזן תמונה לספר">
                        <b-input v-model="model.imageUrl"></b-input>
                    </b-field>
                    <!-- <b-field label="סטטוס">
                        <b-checkbox style="gap: 10px" v-model="model.status" label="" type="is-link is-light"
                            class="checkbox">
                            {{ model.status ? ' פעיל' : 'לא פעיל' }}
                        </b-checkbox>
                    </b-field> -->
                </div>
            </section>
            <footer class="modal-card-foot">
                <b-button :loading="isLoading" @click="save" label="שמור" type="is-primary" />
                <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
            </footer>
        </div>
    </div>
</template>

<script>
import AdminService from "@/services/AdminService";
import { ToastProgrammatic as Toast } from "buefy";

export default {
    name: "DialogReportDriverAssign",
    props: ["onSuccess", "store"],
    components: {
        // NewActivity,
    },
    computed: {
    },
    data() {
        return {
            submitted: false,
            isLoading: false,
            loading: false,
            model: {
                title: null,
                subtitle: null,
                author: null,
                link: null,
                imageUrl: null,
            }
        };
    },
    methods: {
        save() {
            this.submitted = true;
            if (this.model.title) {
                this.isLoading = true;
                AdminService.addBookInBank(this.model)
                    .then(() => {
                        // this.reports.map((t, idx) => (
                        //     t.activities.unshift(r[idx].data)
                        // ));
                        this.$emit("close");
                        if (this.onSuccess) this.onSuccess();
                        window.location.reload();
                        Toast.open({
                            type: "is-success",
                            message: "הפעולה בוצעה!",
                            duration: 4000,
                        });
                    }).catch(() => {
                        Toast.open({
                            type: "is-danger",
                            message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
                            duration: 8000,
                        });
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            }
        },
    },
};
</script>

<style lang="scss"></style>
