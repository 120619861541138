var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "AdminAnalyticsYearly__View" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "AdminAnalyticsYearly__Details" },
      [
        _c(
          "b-field",
          [
            _c(
              "b-slider",
              {
                attrs: {
                  tooltip: false,
                  size: "is-medium",
                  min: 2013,
                  max: 2023,
                  step: 1,
                  ticks: ""
                },
                on: { change: _vm.getAnalytics },
                model: {
                  value: _vm.years,
                  callback: function($$v) {
                    _vm.years = $$v
                  },
                  expression: "years"
                }
              },
              [
                _vm._l(
                  [
                    2013,
                    2014,
                    2015,
                    2016,
                    2017,
                    2018,
                    2019,
                    2020,
                    2021,
                    2022,
                    2023
                  ],
                  function(year) {
                    return [
                      _c(
                        "b-slider-tick",
                        { key: year, attrs: { value: year } },
                        [_vm._v(_vm._s(year))]
                      )
                    ]
                  }
                )
              ],
              2
            )
          ],
          1
        ),
        _vm.barChartData.labels
          ? _c(
              "div",
              { staticClass: "bar-chart" },
              [
                _c("b-loading", {
                  attrs: { "is-full-page": false, "can-cancel": false },
                  model: {
                    value: _vm.isLoading,
                    callback: function($$v) {
                      _vm.isLoading = $$v
                    },
                    expression: "isLoading"
                  }
                }),
                _c("BarChart", {
                  attrs: {
                    "chart-data": _vm.barChartData,
                    options: _vm.barChartOptions,
                    width: 800
                  }
                })
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "AdminAnalyticsYearly__Title" }, [
      _c("h1", [_vm._v('דו"ח פילוח שנתי')]),
      _c("p", [
        _vm._v(
          "בדוח זה ניתן לצפות בנתוני דוחות של טווח שנים. העמודות מייצגות את מספר הרכבים המנוהלים, כמו הדוחות, דוחות ששולמו, והסכום שיש לשלם בכל שנה."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }