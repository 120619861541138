<template>
  <div class="LessonEntityDetails__Component">
    <FieldInlineText label="שם וידאו ראשון" @onChange="onChange" v-model="model.name" />

    <FieldInlineSelect label="קטגוריה" @onChange="onChange" :filterable="true" :clearable="true"
      :optionsAsync="getCategories" v-model="model.lessonCategoryId" />

    <FieldInlineSelect label="סוג" @onChange="onChange" :filterable="true" :clearable="true"
      :optionsAsync="getVideoTypes" v-model="model.videoTypeId" />

    <FieldInlineText label="שם וידאו שני" @onChange="onChange" v-model="model.name2" />

    <FieldInlineText label="שם וידאו שלישי" @onChange="onChange" v-model="model.name3" />

    <FieldInlineText label="שם וידאו רביעי" @onChange="onChange" v-model="model.name4" />

    <FieldInlineText label="קישור ראשון" @onChange="onChange" v-model="model.link1" />

    <FieldInlineText label="קישור שני" @onChange="onChange" v-model="model.link2" />

    <FieldInlineText label="קישור שלישי" @onChange="onChange" v-model="model.link3" />

    <FieldInlineText label="HTML" @onChange="onChange" v-model="model.htmlContent" />

    <FieldInlineText label="תצוגה" @onChange="onChange" v-model="model.thumbnailUrl" />

    <FieldInlineText type="number" label="משך הוידאו" @onChange="onChange" v-model="model.durationInSeconds" />

    <div class="checkbox-container">
      <b-switch class="ltr" @input="onChange" v-model="model.status">{{
        model.status ? "פעיל" : "לא פעיל"
      }}</b-switch>

      <b-switch class="ltr" @input="onChange" v-model="model.isInteractiveVideo">{{
        model.isInteractiveVideo ? "אינטראקטיבי" : "לא אינטראקטיבי"
      }}</b-switch>
    </div>

  </div>
</template>

<script>
import FieldInlineText from '@/components/Fields/FieldInlineText.vue';
// import FieldInlineTextArea from '@/components/Fields/FieldInlineTextArea.vue';
// import FieldInlineDate from '@/components/Fields/FieldInlineDate.vue';
// import FieldInlineDateTime from '@/components/Fields/FieldInlineDateTime.vue';
import FieldInlineSelect from '@/components/Fields/FieldInlineSelect.vue';
import { mapGetters, mapMutations } from 'vuex';
import CoursesService from '../services/CoursesService';

export default {
  name: 'LessonEntityDetails',
  components: {
    FieldInlineText,
    FieldInlineSelect,
    // FieldInlineDate,
    // FieldInlineTextArea,
  },
  props: ['readOnly'],
  data() {
    return {
      model: {
        id: null,
        name: null,
        name2: null,
        name3: null,
        name4: null,
        link1: null,
        link2: null,
        link3: null,
        htmlContent: null,
        status: null,
        isInteractiveVideo: null,
        thumbnailUrl: null,
        durationInSeconds: null,
        lessonCategoryId: null,
        videoTypeId: null
      },
    };
  },
  computed: {
    ...mapGetters('videoBank', [
      'form',
    ]),
    changesAmount() {
      return Object.keys(this.diff(this.model, this.form)).length;
    },
  },
  created() {
    this.model = { ...this.model, ...this.form };
    console.log(this.model);
  },
  methods: {
    diff(o1, o2) {
      const diff = Object.keys(o2).reduce((diff1, key) => {
        if (o1[key] && typeof o1[key].getMonth === 'function') {
          if (new Date(o1[key]).toString() === new Date(o2[key]).toString()) return diff1;
        }
        if (typeof o1[key] === 'number') {
          if (parseInt(o1[key], 10) === parseInt(o2[key], 10)) return diff1;
        }
        if (o1[key] && typeof o1[key] === 'object' && typeof o1[key].getMonth !== 'function') {
          if (o1[key]?.Id === o2[key]?.Id) return diff1;
        }
        if (o1[key] === o2[key]) return diff1;
        return {
          ...diff1,
          [key]: o2[key],
        };
      }, {});

      return diff;
    },
    onChange() {
      this.$emit('onFormChange', this.changesAmount);
    },
    getCategories() {
      return () =>
        CoursesService.getCategories().then((r) =>
          r.data.map((i) => ({ Value: i.id, Text: i.name }))
        );
    },
    getVideoTypes() {
      return () =>
        CoursesService.getVideoTypes().then((r) =>
          r.data.map((i) => ({ Value: i.id, Text: i.name }))
        );
    },
    getVerdictCollections() {
      return () =>
        CoursesService.getVerdictCollections().then((r) =>
          r.data.map((i) => ({ Value: i.id, Text: i.name }))
        );
    },
    getLaws() {
      return () =>
        CoursesService.getLawCollections().then((r) =>
          r.data.map((i) => ({ Value: i.id, Text: i.name }))
        );
    },
    cancel() {
      this.model = { ...this.model, ...this.form };
    },
    save() {
      this.$emit('onFormSave', {
        model: this.model,
        cancel: this.cancel,
        setRestorePoint: this.setRestorePoint,
      });
    },
    setRestorePoint() {
      this.storeLessonDetails(this.model);
    },
    refresh() {
      this.model = { ...this.model, ...this.form };
    },
    ...mapMutations('videoBank', [
      'storeLessonDetails',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.checkbox-container {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}
</style>
