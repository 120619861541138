var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "DialogAddLaw__Component" }, [
    _c("div", { staticClass: "modal-card" }, [
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v(_vm._s(_vm.row ? "עדכון" : "הוספת") + " מקבץ פסיקה")
        ]),
        _c("button", {
          staticClass: "delete",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        })
      ]),
      _c("section", { staticClass: "modal-card-body" }, [
        _c(
          "div",
          { staticClass: "DialogAddLaw__Component__Loading" },
          [
            _c("FieldInlineText", {
              attrs: { label: "שם" },
              on: { onChange: _vm.onChange },
              model: {
                value: _vm.model.name,
                callback: function($$v) {
                  _vm.$set(_vm.model, "name", $$v)
                },
                expression: "model.name"
              }
            }),
            _c("FieldInlineSelect", {
              attrs: {
                label: "בחר חוק מהרשימה",
                filterable: true,
                clearable: true,
                reduce: function(item) {
                  return item
                },
                optionsAsync: _vm.getVerdictSummaries
              },
              model: {
                value: _vm.chosenLaw,
                callback: function($$v) {
                  _vm.chosenLaw = $$v
                },
                expression: "chosenLaw"
              }
            }),
            _vm.chosenLaw
              ? _c("div", { staticClass: "new-law" }, [
                  _c("div", { staticClass: "item" }, [
                    _c("p", [_vm._v(_vm._s(_vm.chosenLaw.Text))]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.chosenLaw.sortOrder,
                          expression: "chosenLaw.sortOrder"
                        }
                      ],
                      attrs: { type: "number" },
                      domProps: { value: _vm.chosenLaw.sortOrder },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.chosenLaw,
                            "sortOrder",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "add",
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function($event) {
                            return _vm.addVerdictItem()
                          }
                        }
                      },
                      [_c("b-icon", { attrs: { pack: "fa", icon: "plus" } })],
                      1
                    )
                  ])
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "items" },
              _vm._l(_vm.model.verdictCollectionItems, function(item) {
                return _c("div", { key: item.id, staticClass: "item" }, [
                  _c("p", [_vm._v(_vm._s(item.verdictSummaryName))]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: item.sortOrder,
                        expression: "item.sortOrder"
                      }
                    ],
                    attrs: { type: "number" },
                    domProps: { value: item.sortOrder },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(item, "sortOrder", $event.target.value)
                      }
                    }
                  }),
                  _c("div", {
                    staticClass: "delete",
                    on: {
                      click: function($event) {
                        return _vm.deleteVerdictItem(item.verdictSummaryId)
                      }
                    }
                  })
                ])
              }),
              0
            )
          ],
          1
        )
      ]),
      _c(
        "footer",
        { staticClass: "modal-card-foot" },
        [
          _c("b-button", {
            attrs: {
              loading: _vm.isLoading,
              label: "שמור",
              type: "is-primary"
            },
            on: { click: _vm.save }
          }),
          _c("b-button", {
            attrs: { disabled: _vm.isLoading, label: "סגור" },
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }