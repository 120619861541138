<template>
    <div class="AdminLocationDataScan__View">
        <h1>סריקת נתוני מיקום</h1>
        <div class="AdminLocationDataScan__View__Form">
            <div class="field">
                <b-field label="מציג פרופילים מנוהלים">
                    <b-switch v-model="isManaged">
                        {{ isManaged ? 'כן' : 'לא', }}
                    </b-switch>
                </b-field>
                <b-field label="סוגי רכבים">
                    <b-switch v-model="isType">
                        {{ isType ? 'מנוהלים בלבד' : 'הכל', }}
                    </b-switch>
                </b-field>
                <b-field label="סוגי שדות">
                    <b-switch v-model="isField">
                        {{ isField ? 'ריקים בלבד' : 'הכל', }}
                    </b-switch>
                </b-field>
                <b-field label="בחר פרופיל">
                    <v-select dir="rtl" label="Text" v-model="profiles" :options="users" :clearable="true" :multiple="true"
                        :searchable="true" :close-on-select="false" :placeholder="'בחר ערך מהרשימה'">
                        <div slot="no-options">לא נמצאו ערכים דומים</div>
                    </v-select>
                </b-field>
                <b-button @click="importData" class="mt-5" type="is-primary" :loading="isLoading">סרוק נתוני מיקום</b-button>
            </div>
        </div>
    </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/src/scss/vue-select.scss";
import ProfileService from "@/services/ProfileService";
import ReportService from "@/services/ReportService";

export default {
    name: "AdminLocationDataScan",
    components: {
        vSelect,
    },
    data() {
        return {
            users: [],
            profiles: [],
            isManaged: false,
            isType: false,
            isField: false,
            isLoading: false,
        };
    },
    created() {
        this.getProfiles(this.isManaged);
    },
    methods: {
        importData() {
            this.$buefy.dialog.confirm({
                title: "סריקת נתוני מיקום",
                message: "האם להתחיל את הסריקה?",
                confirmText: "כן",
                cancelText: "לא",
                type: "is-primary",
                closeOnConfirm: true,
                hasIcon: true,
                onConfirm: (a, instance) => {
                    this.isLoading = true;
                    ReportService.scanData(this.isType, this.isField)
                        .then(() => {
                            this.$buefy.toast.open({
                                type: "is-success",
                                message: "הפעולה בוצעה בהצלחה!",
                                duration: 4000,
                            });
                        })
                        .catch(() => {
                            this.$buefy.toast.open({
                                type: "is-danger",
                                message: "קרתה תקלה. לא ניתן לבצע את הפעולה!",
                                duration: 8000,
                            });
                        })
                        .finally(() => {
                            this.isLoading = false;
                            instance.close();
                        });
                },
            });
        },
        getProfiles(isManaged) {
            ProfileService.allMapped(isManaged).then((r) => {
                this.users = r;
            });
        },
    },
    watch: {
        isManaged(value) {
            this.getProfiles(value);
        },
        isType(value) {
            this.getProfiles(value);
        },
        isField(value) {
            this.getProfiles(value);
        },
    },
};
</script>

<style lang="scss" scoped>
.AdminLocationDataScan__View {
    padding: 20px;
}

h1 {
    font-size: 25px;
    padding: 5px 0 20px 0;
}

.field {
    width: 400px;
}

::v-deep .switch {
    direction: ltr;
}
</style>
