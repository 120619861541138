<template>
  <div class="TableSelectable__Component">
    <div class="table-scrollable">
      <table v-if="slicedData" class="table">
        <tr v-if="headersModel">
          <th class="row-number" v-if="showRowNumber">#</th>
          <th v-for="(header, headerIndex) in headersModel" :key="headerIndex">
            <b-select
              :ref="`select_${headerIndex}`"
              placeholder="בחר עמודה"
              @input="onSelect(header, headerIndex, $event)"
            >
              <option value="$reset">בחר עמודה</option>
              <option
                v-for="option in header"
                :class="{ 'is-required': option.IsRequired }"
                :value="option"
                :disabled="isSelected(option)"
                :key="option.Name"
              >
                {{ option.Name }}
              </option>
            </b-select>
          </th>
        </tr>
        <tr
          :class="{
            ignore: index < startingRow,
            'is-error': !isRowValid(index),
          }"
          v-for="(item, index) in slicedData"
          :key="index"
        >
          <td class="row-number" v-if="showRowNumber">{{ index + 1 }}</td>
          <td
            :class="{
              'is-header': !index,
              'is-danger': !isCellValid(index, valueIndex),
            }"
            v-for="(value, valueIndex) in item"
            :key="valueIndex"
          >
            {{ value }}
          </td>
        </tr>
      </table>
      <table v-else class="table">
        <tr v-if="headersModel">
          <th class="row-number" v-if="showRowNumber">#</th>
          <th v-for="(header, headerIndex) in headersModel" :key="headerIndex">
            <b-select
              :ref="`select_${headerIndex}`"
              placeholder="בחר עמודה"
              @input="onSelect(header, headerIndex, $event)"
            >
              <option value="$reset">בחר עמודה</option>
              <option
                v-for="option in header"
                :class="{ 'is-required': option.IsRequired }"
                :value="option"
                :disabled="isSelected(option)"
                :key="option.Name"
              >
                {{ option.Name }}
              </option>
            </b-select>
          </th>
        </tr>
        <tr
          :class="{
            ignore: index < startingRow,
            'is-error': !isRowValid(index),
          }"
          v-for="(item, index) in data"
          :key="index"
        >
          <td class="row-number" v-if="showRowNumber">{{ index + 1 }}</td>
          <td
            :class="{
              'is-header': !index,
              'is-danger': !isCellValid(index, valueIndex),
            }"
            v-for="(value, valueIndex) in item"
            :key="valueIndex"
          >
            {{ value }}
          </td>
        </tr>
      </table>
    </div>
    <div class="indicators">
      <div class="total-rows">כמות שורות: {{ totalRows }}</div>
      <div :class="{ 'total-errors': rowsWithErrors }">
        שורות שגויות: {{ rowsWithErrors }}
      </div>
      <div :class="{ 'total-to-import': rowsToImport }">
        תקינים: {{ rowsToImport }}
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-continue */
import moment from "moment";

const stringSimilarity = require("string-similarity");

export default {
  name: "TableSelectable",
  props: {
    data: Array,
    slicedData: Array,
    headers: Array,
    startingRow: {
      type: Number,
      default: 0,
    },
    showRowNumber: Boolean,
    removeSelectedFromList: {
      type: Boolean,
      default: true,
    },
    isResetHeaders: Boolean,
  },
  created() {
    this.headersModel = [...this.headers];
  },
  data() {
    return {
      headersModel: [],
      validationErrors: {},
    };
  },
  computed: {
    rowsWithErrors() {
      return Object.keys(this.validationErrors).length;
    },
    totalRows() {
      return this.data.length;
    },
    rowsToImport() {
      return this.isValid()
        ? this.totalRows - this.rowsWithErrors - this.startingRow
        : 0;
    },
  },
  methods: {
    export() {
      const headers = this.headersModel[0];
      const sortedHeaders = headers
        .filter((i) => i.isSelected)
        .sort((a, b) => a.headerIndex - b.headerIndex);
      const columns = sortedHeaders.map((h) => h.headerIndex);
      const results = [];
      for (let i = this.startingRow; i < this.data.length; i += 1) {
        const row = [];
        for (let j = 0; j < this.data[i].length; j += 1) {
          if (columns.includes(j)) {
            row.push(this.data[i][j]);
          }
        }
        if (row.length) {
          results.push(row);
        }
      }
      return {
        rows: results,
        originalRows: this.data,
        columns: sortedHeaders.map((i) => i.Name),
      };
    },
    isValid() {
      const fields = this.headersModel[0];
      return fields.filter((f) => f.IsRequired).every((i) => i.isSelected);
    },
    isCellValid(row, column) {
      return !this.validationErrors[row] || !this.validationErrors[row][column];
    },
    isRowValid(row) {
      return !this.validationErrors[row];
    },
    onSelect(header, headerIndex, option) {
      if (option === "$reset") {
        this.clearSelected(header, headerIndex);
        this.validate();
        return;
      }

      const selectedOption = header.find((i) => i.Name === option.Name);
      this.clearSelected(header, headerIndex);

      this.$set(selectedOption, "isSelected", true);
      this.$set(selectedOption, "headerIndex", headerIndex);
      this.validate();
    },
    isSelected(option) {
      return this.headersModel[0].find((i) => i.Name === option.Name)
        .isSelected;
    },
    clearSelected(header, headerIndex) {
      const currentSelected = header.filter(
        (i) => i.headerIndex === headerIndex
      );
      currentSelected.forEach((i) => {
        this.$set(i, "isSelected", false);
      });
    },
    validate() {
      this.validationErrors = {};
      const fields = this.headersModel[0];

      fields
        .filter((f) => f.isSelected && f.headerIndex)
        .forEach((field) => {
          this.validateColumn(field);
        });
    },
    validateColumn(field) {
      let isValid = true;
      // eslint-disable-next-line no-restricted-syntax
      const { data } = this;
      for (let i = this.startingRow; i < data.length; i += 1) {
        const value = data[i][field.headerIndex];
        if (field.IsRequired && !value && field.Type !== "Bool") {
          this.validationErrors[i] = this.validationErrors[i] || {};
          this.validationErrors[i][field.headerIndex] = true;
          isValid = false;
          continue;
        }
        const optBool = ["0", "False", "false", "1", "True", "true"];
        if (
          field.IsRequired &&
          !optBool.includes(value) &&
          // !(
          //   value === "0" ||
          //   value === "False" ||
          //   value === "false" ||
          //   value === "1" ||
          //   value === "True" ||
          //   value === "true"
          // ) &&
          field.Type === "Bool"
        ) {
          this.validationErrors[i] = this.validationErrors[i] || {};
          this.validationErrors[i][field.headerIndex] = true;
          isValid = false;
          continue;
        }
        if (field.Type === "Dobule" && value) {
          if (!/^-?\d+$/.test("value")) {
            this.validationErrors[i] = this.validationErrors[i] || {};
            this.validationErrors[i][field.headerIndex] = true;
            isValid = false;
            continue;
          }
        }
        if (field.Type === "DateTime" && value) {
          const date = moment(value, "DD/MM/YYYY hh:mm");
          if (!date.isValid()) {
            this.validationErrors[i] = this.validationErrors[i] || {};
            this.validationErrors[i][field.headerIndex] = true;
            isValid = false;
            continue;
          }
        }
        if (field.Type === "Bool" && value) {
          if (value === "0" || value === "False" || value === "false") {
            data[i][field.headerIndex] = "false";
            // data[i][field.headerIndex] = false;
          } else if (value === "1" || value === "True" || value === "true") {
            data[i][field.headerIndex] = "true";
            // data[i][field.headerIndex] = true;
          } else {
            this.validationErrors[i] = this.validationErrors[i] || {};
            this.validationErrors[i][field.headerIndex] = true;
            isValid = false;
            continue;
          }
        }
      }
      return isValid;
    },
    analyze() {
      const headers = this.data[0];
      const fields = this.headersModel[0];
      const percent = 0.6;
      const requiredFields = fields.filter((i) => i.IsRequired);
      this.resetAllRequiredFields();
      headers.forEach((h, index) => {
        const name = h;
        if (stringSimilarity.compareTwoStrings("מס דוח", name) > percent) {
          this.assignHeader(
            index,
            requiredFields.find((i) => i.Name === "ReportNumber")
          );
        }
        if (stringSimilarity.compareTwoStrings("מספר רכב", name) > percent) {
          this.assignHeader(
            index,
            requiredFields.find((i) => i.Name === "PlateNumber")
          );
        }
        if (stringSimilarity.compareTwoStrings("כתובת", name) > percent) {
          this.assignHeader(
            index,
            requiredFields.find((i) => i.Name === "Address")
          );
        }
        if (stringSimilarity.compareTwoStrings("DATETAKEN", name) > percent) {
          this.assignHeader(
            index,
            requiredFields.find((i) => i.Name === "DateTaken")
          );
        }
        if (stringSimilarity.compareTwoStrings("סכום", name) > percent) {
          this.assignHeader(
            index,
            requiredFields.find((i) => i.Name === "OriginalAmount")
          );
        }
        if (stringSimilarity.compareTwoStrings("ISPAID", name) > percent) {
          this.assignHeader(
            index,
            requiredFields.find((i) => i.Name === "IsPaid")
          );
        }
        if (stringSimilarity.compareTwoStrings("עיריה", name) > percent) {
          this.assignHeader(
            index,
            requiredFields.find((i) => i.Name === "Municipality")
          );
        }
      });
    },
    assignHeader(columnIndex, field) {
      this.$set(field, "headerIndex", columnIndex);
      this.$set(field, "isSelected", true);
      console.log(this.$refs[`select_${columnIndex}`]);
    },
    resetAllRequiredFields() {
      const fields = this.headersModel[0];
      const requiredFields = fields.filter((i) => i.IsRequired);
      requiredFields.forEach((i) => {
        this.$set(i, "isSelected", false);
        this.$set(i, "headerIndex", null);
      });
    },
    resetHeaders() {
      this.headersModel.forEach((h, i) => {
        const aa = this.$refs[`select_${i}`];
        aa[0].selected = null;
        this.clearSelected(h, i);
        this.validate();
      });
    },
  },
  watch: {
    startingRow() {
      this.validate();
    },
    isResetHeaders() {
      if (this.isResetHeaders) {
        this.resetHeaders();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table-scrollable {
  width: calc(100vw - 340px);
  overflow-x: auto;
  overflow-y: auto;
  height: calc(100vh - 500px);

  table {
    table-layout: fixed;
    overflow-x: auto;
    width: 100%;

    tr {
      &.ignore {
        opacity: 0.2;
      }
      &.is-error {
        background-color: #ffe8e8;
      }
    }

    th {
      background: white;
      position: sticky;
      top: 0; /* Don't forget this, required for the stickiness */
      box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
    }
    td,
    th {
      width: 200px;

      &.row-number {
        width: 50px;
        text-align: center;
      }

      &.is-header {
        font-weight: bold;
      }
    }

    select option {
      color: black;

      &.is-required {
        color: red;
      }
      &:disabled {
        color: silver;
      }
    }
  }
}
.indicators {
  display: flex;
  justify-content: space-around;

  & > div {
    padding: 10px;
    width: 100%;
    text-align: center;

    &.total-to-import {
      background-color: #daffda;
    }
    &.total-rows {
      background-color: #daeeff;
    }
    &.total-errors {
      background-color: #ffdada;
    }
  }
}
</style>
