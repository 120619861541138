var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Reports__Import__View" }, [
    _c("h1", [_vm._v("עדכון אורכים ותמונות חסרות לוידאו")]),
    _c("div", { staticClass: "Reports__Import__View__Steps" }, [
      _c(
        "div",
        { staticClass: "loading" },
        [
          _c("b-loading", {
            attrs: { "is-full-page": true, "can-cancel": false },
            model: {
              value: _vm.isLoading,
              callback: function($$v) {
                _vm.isLoading = $$v
              },
              expression: "isLoading"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "b-field",
            { attrs: { label: "עדכון אורכים חסרים לוידאו" } },
            [
              _c("b-button", { on: { click: _vm.updateVideosDuration } }, [
                _vm._v(" עדכון ")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "b-field",
            { attrs: { label: "עדכון אורך לסרטון בודד" } },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.videoId,
                    expression: "videoId"
                  }
                ],
                attrs: { type: "number", placeholder: "מספר הסרטון" },
                domProps: { value: _vm.videoId },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.videoId = $event.target.value
                  }
                }
              }),
              _c("b-button", { on: { click: _vm.updateVideoDuration } }, [
                _vm._v("עדכון")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "b-field",
            { attrs: { label: "עדכון תמונות חסרות לוידאו" } },
            [
              _c("b-button", { on: { click: _vm.updateVideosThumbnails } }, [
                _vm._v(" עדכון ")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "b-field",
            { attrs: { label: "עדכון תמונה לסרטון בודד" } },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.videoThumbnailId,
                    expression: "videoThumbnailId"
                  }
                ],
                attrs: { type: "number", placeholder: "מספר הסרטון" },
                domProps: { value: _vm.videoThumbnailId },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.videoThumbnailId = $event.target.value
                  }
                }
              }),
              _c("b-button", { on: { click: _vm.updateVideoThumbnail } }, [
                _vm._v("עדכון")
              ])
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }