<template>
  <div class="Reports__Import__View">
    <h1>ייבוא נהגים</h1>
    <div class="Reports__Import__View__Steps">
      <section>
        <b-steps v-model="activeStep">
            <b-step-item
              :label="'בחירת קובץ'">
              <div class="ChooseFile__Step">
                  <b-field label="בחר קובץ לייבוא">
                    <b-field class="file is-primary" :class="{'has-name': !!file}">
                        <b-upload v-model="file" class="file-label" rounded>
                            <span class="file-cta">
                                <b-icon class="file-icon" icon="upload"></b-icon>
                                <span class="file-label">בחר קובץ...</span>
                            </span>
                            <span class="file-name" v-if="file">
                                {{ file.name }}
                            </span>
                        </b-upload>
                    </b-field>
                </b-field>
              </div>
            </b-step-item>
             <b-step-item :clickable="false"
              :label="'הבאת מידע'">
              <div class="Progress__Step">
                <b-progress type="is-primary"
                  :value="progress" show-value format="percent"></b-progress>
              </div>
            </b-step-item>
             <b-step-item
              :label="'מיפוי שדות'">
              <div class="starting-row">
                 <b-field label="ייבא נתונים החל משורה">
                  <b-numberinput min="0" v-model="startingRow"></b-numberinput>
                </b-field>
                <!-- <b-tooltip label='זיהוי חכם מנסה לזהות את שדות החובה ע"פ שם הכותרת. במידה ואין כותרות, לא יתבצע זיהוי.'
                  position="is-left">
                  <b-button @click="smartAnalyze" class="mr-3" type="is-primary">בצע זיהוי חכם</b-button>
                </b-tooltip> -->

              </div>
              <div class="table-scrollable">
                  <table-selectable
                    ref="table"
                    v-if="headers.length"
                    :show-row-number="true"
                    :starting-row="startingRow"
                    :headers="headers"
                    :data="table" />
              </div>
            </b-step-item>
            <b-step-item :label="'בחירת פרופיל'">
               <div class="is-flex">
                  <div class="Field">
                    <b-field label="חפש">
                      <b-input v-model="profileTerm"></b-input>
                    </b-field>
                  </div>
                  <div class="Field">
                    <b-field label="הצג פרופילים מנוהלים">
                      <b-switch v-model="isManaged" class="ltr pt-2">
                        {{isManaged ? 'כן': 'לא'}}
                      </b-switch>
                    </b-field>
                  </div>
               </div>
              <b-table
                paginated
                :sticky-header="true"
                :striped="true"
                :data="users"
                :pagination-simple="true"
                :selected.sync="selectedProfile"
                :hoverable="true"
                per-page="30"
                :loading="isLoadingProfiles"
                default-sort-direction="asc"
                default-sort="Id"
                @click="onProfileClick"
                :mobile-cards="true">

                  <b-table-column field="Id" label="מזהה" v-slot="props" centered sortable >
                    {{ props.row.Id }}
                  </b-table-column>

                  <b-table-column label="ח.פ/ת.ז" v-slot="props" centered >
                    {{ props.row.IdNumber }}
                  </b-table-column>

                  <b-table-column field="Name" label="שם" v-slot="props" sortable>
                    {{ props.row.Name }}
                  </b-table-column>

                  <b-table-column label="כתובת" v-slot="props">
                    {{ getAddress(props.row) }}
                  </b-table-column>

                  <b-table-column label="מיקוד" v-slot="props">
                    {{ props.row.ZipCode }}
                  </b-table-column>

                  <b-table-column field="IsLeasingCompany" label="חברת ליסינג" v-slot="props"
                    centered sortable>
                    {{ props.row.IsLeasingCompany ? 'כן' : 'לא' }}
                  </b-table-column>

                </b-table>
            </b-step-item>
            <b-step-item :label="'מייבא נתונים'" :visible="isImporting">
              <div class="importing-step">
                <b-loading :is-full-page="false" v-model="isImporting" :can-cancel="false"></b-loading>
              </div>
            </b-step-item>
            <b-step-item :label="'סטטוס'">
              <div class="status-step">
                <div class="is-flex is-justify-content-flex-end">
                  <b-tooltip label="ייצוא לאקסל">
                      <b-button @click="exportToExcel">
                        <b-icon
                            icon="file-excel-outline"
                          >
                        </b-icon>
                      </b-button>
                  </b-tooltip>
                </div>
                <table class="table">
                  <thead>
                    <tr>
                      <th v-for="(header, index) in results.headers" :key="index">
                        {{header}}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr :class="{'has-error': !row.Succeeded}" v-for="(row, rowIndex) in results.rows" :key="rowIndex">
                      <td v-for="(value, valueIndex) in row.data" :key="valueIndex">
                        {{value}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-step-item>

            <template
                #navigation="{previous, next}">
                <div class="TollRoads__Import__View__Steps__Navigations" v-show="activeStep !== 6">
                  <b-button
                    outlined
                    type="is-primary"
                    icon=""
                    @click.prevent="onPrevious(previous)">
                    חזור
                  </b-button>
                  <b-button
                      outlined
                      type="is-primary"
                      icon=""
                      @click.prevent="onNext(next)">
                      המשך
                  </b-button>
                </div>
            </template>
            <template v-if="activeStep === 5">
              <div class="mt-5">
                <b-button type="is-primary" @click="reset">התחל ייבוא חדש</b-button>
              </div>
            </template>
        </b-steps>
      </section>
    </div>
  </div>
</template>
<script>
import FilesService from '@/services/FilesService';
import TableSelectable from '@/components/TableSelectable.vue';
import DriverService from '@/services/DriverService';
import ProfileService from '@/services/ProfileService';
import { saveAs } from 'file-saver';

const headers = [
    { Name: 'Name', IsRequired: true, Type: 'String' },
    { Name: 'PhoneNumber', IsRequired: true, Type: 'Double' },
    { Name: 'IdNumber', IsRequired: true, Type: 'Double' },
    { Name: 'LicenseNumber', IsRequired: true, Type: 'Double' },
    { Name: 'TrackerDriverId', IsRequired: false, Type: 'Double' },
    { Name: 'City', IsRequired: false, Type: 'String' },
    { Name: 'Street', IsRequired: false, Type: 'String' },
    { Name: 'HouseNumber', IsRequired: false, Type: 'Double' },
    { Name: 'DriverNumber', IsRequired: false, Type: 'Double' },
    { Name: 'ZipCode', IsRequired: false, Type: 'Double' },
];

export default {
  components: { TableSelectable },
  name: 'AdminImportDrivers',
  data() {
    return {
        isImporting: false,
        profileTerm: null,
        activeStep: 0,
        file: null,
        randomProgressRef: null,
        progress: 0,
        data: [],
        results: {
          headers: [],
          rows: [],
        },
        selectedProfile: null,
        isLoadingProfiles: false,
        isManaged: true,
        users: [],
        isFirstTime: false,
        date: null,
        getScreenShot: false,
        comment: null,
        startingRow: 1,
        headers: [],
        currentPage: 1,
        perPage: 30,
        headerFields: [],
    };
  },
  computed: {
    table() {
      return this.data?.data;
    },
    excelMimeType() {
      return 'application/vnd.ms-excel';
    },
  },
  async created() {
    await this.searchUsers();
  },
  methods: {
    async exportToExcel() {
      let data = [];
      data.push(this.results.headers);
      data = data.concat(this.results.rows.map((i) => i.data));
      const { data: r } = await FilesService.postData(data);
      const blob = new Blob([r], { type: this.excelMimeType });
      saveAs(blob, 'ייבוא נהגים.xlsx');
    },
    reset() {
      this.activeStep = 0;
      this.progress = 0;
      this.file = null;
      this.isFirstTime = false;
      this.date = null;
      this.data = [];
      this.startingRow = 1;
      this.currentPage = 1;
      this.perPage = 30;
      this.getScreenShot = false;
      this.comment = null;
      this.selectedProfile = null;
      this.profileTerm = null;
      this.headers = [...headers];
      this.results = {
        headers: [],
        data: [],
      };
    },
    async searchUsers() {
      this.isLoadingProfiles = true;
      const { data: profiles } = await ProfileService.all(this.isManaged);
      this.users = profiles;
      this.origData = [...this.users];
      this.isLoadingProfiles = false;
    },
    onProfileClick(row) {
      this.selectedProfile = row;
    },
    smartAnalyze() {
      this.$buefy.dialog.confirm({
        title: 'זיהוי חכם',
        message: 'שים לב, המערכת תבצע עבורך זיהוי חכם. במידה ויימצא שדה מתאים היא תדרוס את הבחירה הקיימת. האם אתה בטוח שברצונך להמשיך?',
        confirmText: 'כן',
        cancelText: 'לא',
        onConfirm: () => this.$refs.table.analyze(),
      });
    },
    async onNext() {
      if (this.activeStep === 0 && this.file) {
        await this.step1();
        return;
      }
      if (this.activeStep === 2) {
        this.step2();
        return;
      }
      if (this.activeStep === 3) {
        this.step4();
      }
    },
    getAddress(row) {
      if (!row.City && !row.Street && !row.HouseNumber) return '';
      return `${row.City}, ${row.Street} ${row.HouseNumber}`;
    },
    async step4() {
      if (!this.selectedProfile) {
        return;
      }
      this.isImporting = true;
      this.activeStep += 1;
      const exportedData = this.$refs.table.export();
      const data = exportedData.rows.map((row) => ({
          IdNumber: row[exportedData.columns.indexOf('IdNumber')],
          LicenseNumber: row[exportedData.columns.indexOf('LicenseNumber')],
          Name: row[exportedData.columns.indexOf('Name')],
          PhoneNumber: row[exportedData.columns.indexOf('PhoneNumber')],
          TrackerDriverId: row[exportedData.columns.indexOf('TrackerDriverId')],
          City: row[exportedData.columns.indexOf('City')],
          Street: row[exportedData.columns.indexOf('Street')],
          HouseNumber: row[exportedData.columns.indexOf('HouseNumber')],
          DriverNumber: row[exportedData.columns.indexOf('DriverNumber')],
          ZipCode: row[exportedData.columns.indexOf('ZipCode')],
      }));

      this.results.headers = [...exportedData.columns];
      this.results.headers.push('Errors');

      const reqs = data.map((i) => DriverService.postDriver(i, {
        'Profile-Id': this.selectedProfile.Id,
      })
      .then((r) => {
        this.results.rows.push({ data: Object.values(JSON.parse(r.config.data)), Succeeded: true });
      })
      .catch((err) => {
          let errors;
          if (Object.values(err.response?.data)[1] && !err.response?.data.ErrorMessage) {
              errors = Object.values(Object.values(err.data)[1]).flat();
          }
          this.results.rows.push({
            data: Object.values(JSON.parse(err.config.data)).concat([errors || err.response?.data?.Message || err.response.data.ErrorMessage || err.response.statusText]),
            Succeeded: false,
          });
      }));
      Promise.all(reqs).finally(() => {
        this.activeStep += 1;
      });
    },
    step2() {
      if (this.$refs.table?.isValid()) {
          this.activeStep += 1;
      } else {
        this.$buefy.dialog.alert({
            title: 'שימו לב!',
            message: 'יש להשלים את כל השדות הדרושים על מנת להמשיך בתהליך',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            confirmText: 'אוקיי',
        });
      }
    },
    async step1() {
        this.activeStep += 1;
        this.randomProgress();
        this.data = await this.uploadFile();
        if (!this.data) {
        this.onPrevious();
      } else {
        this.headerFields = await this.getHeaders();
        this.headers = this.createHeaders(this.table);
        this.activeStep += 1;
        this.progress = 100;
        clearInterval(this.randomProgressRef);
      }
    },
    buildResults(exportedData, response) {
      const { columns } = exportedData;
      const results = {
        headers: columns.concat('Error1', 'Error2', 'Error3'),
        rows: [],
      };
      const rows = [...response.Rows];
      rows.forEach((r) => {
        const row = {};
        row.Succeeded = r.Succeeded;
        row.data = r.Data.concat(r.Errors);
        results.rows.push(row);
      });
      return results;
    },
    createHeaders(table) {
      const length = this.getMaxHeadersLength(table);
      return Array.from({ length }, () => this.headerFields);
    },
    async getHeaders() {
      return headers;
    },
    getMaxHeadersLength(table) {
      let maxLenth = 0;
      table.forEach((item) => {
        maxLenth = Math.max(item.length, maxLenth);
      });
      return maxLenth;
    },
    onPrevious() {
      if (!this.activeStep) return;
      this.activeStep -= 1;
    },
    randomProgress() {
      this.progress = 0;
      this.randomProgressRef = setInterval(() => {
        this.progress += 5;
        this.progress = Math.min(100, this.progress);
      }, 200);
    },
    uploadFile() {
      const formData = new FormData();
      formData.append('file', this.file);
      return FilesService.getData(formData).catch(() => {
        this.$buefy.dialog.alert({
              title: "שימו לב!",
              message: "קרתה תקלה לא ניתן לבצע את הפעולה",
              type: "is-danger",
              hasIcon: true,
              icon: "times-circle",
              iconPack: "fa",
              confirmText: "אוקיי",
            });
      });
    },
    filterByTerm(value = this.term) {
      this.users = this.origData
      .filter((i) => {
        const values = Object.values(i);
        return values.some((v) => (v
            && typeof v !== 'boolean'
            && typeof v !== 'number' ? !value || v.indexOf(value) > -1 : v === parseInt(value, 10)));
      });
    },
  },
  watch: {
    profileTerm(newValue) {
      this.filterByTerm(newValue);
    },
    async isManaged() {
      await this.searchUsers();
      this.filterByTerm();
    },
  },
};
</script>
<style scoped lang="scss">
  .Reports__Import__View h1 {
    font-size: 25px;
    padding: 25px 25px 0 0 ;
  }
  .Reports__Import__View__Steps {
    padding: 50px 20px 20px 20px;
    display: flex;
    align-content: center;

    ::v-deep .step-content {
      padding: 60px 0 10px 0;
    }

    section {
      width: 100%;
    }

    .ChooseFile__Step {
      display: flex;
      flex-direction: column;
      justify-items: center;
      padding: 30px 0;
      width: 600px;
      margin: 0 auto;

      ::v-deep {
        .field, .file {
          text-align: center;
          justify-content: center;
        }
      }
    }
    .Progress__Step {
      width: 600px;
      padding: 30px 0;
      margin: 0 auto;
    }

    ::v-deep {
      .b-steps .steps .step-items .step-item:not(:first-child)::before,
      .b-steps .steps .step-items .step-item:only-child::before {
          right: -50%;
          left: unset;
      }
      .b-steps .steps .step-items .step-item::before,
      .b-steps .steps .step-items .step-item::after {
        background-position: left bottom;
        background: linear-gradient(to right , #dbdbdb 50%, #7957d5 50%);
        background-size: 200% 100%;
      }
      .b-steps .steps .step-items .step-item.is-active::before,
      .b-steps .steps .step-items .step-item.is-active::after {
        background-position: right bottom;
      }
      .b-steps .steps .step-items .step-item.is-previous::before,
      .b-steps .steps .step-items .step-item.is-previous::after {
        background-position: right bottom;
      }
    }

    .TollRoads__Import__View__Steps__Navigations {
      display: flex;
      justify-content: center;
      ::v-deep button {
        margin: 0 5px;
      }
    }
  }
  ::v-deep {
    .top.level {
        flex-direction: row-reverse;
    }
    .pagination.is-simple {
      flex-direction: row-reverse;
    }
    .b-table .table-wrapper.has-sticky-header {
      height: calc(100vh - 485px);
      overflow-y: auto;
    }
  }
  .starting-row {
    width: 300px;
    margin-bottom: 10px;
    display: flex;
    align-items: self-end;

    .field:not(:last-child) {
      margin-bottom: 0;
    }
  }
  .switch  {
    direction: ltr;
  }
  .settings-step {
    width: 500px;
  }
  .importing-step {
    height: 500px;
  }
  .status-step {

    .table {
      width: 100%;
      margin-top: 30px;

      tbody tr {
        background-color: #daffda;
      }
    }

    .has-error {
      background-color: #ffdada !important;
    }
  }
  ::v-deep .table.is-hoverable tbody tr:not(.is-selected):hover {
      background-color: #eaf0f6 !important;
      cursor: pointer;
  }
  ::v-deep .table.is-striped tbody tr:not(.is-selected):nth-child(even) {
    background-color: #efefef;
  }
</style>
