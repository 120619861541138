<template>
    <div class="DialogReportPaid__Component">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">הוספת וידאו לבנק</p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">
                <div class="DialogReportPaid__Component__Loading">
                    <b-field label="הזן שם לוידאו">
                        <b-input v-model="model.name"></b-input>
                    </b-field>
                    <b-field label="קטגוריה">
                        <FieldInlineSelect label="" @onChange="onChange" :filterable="true" :clearable="true"
                            :optionsAsync="getCategories" v-model="model.lessonCategoryId" />
                    </b-field>
                    <b-field label="הזן שם שני לוידאו">
                        <b-input v-model="model.name2"></b-input>
                    </b-field>
                    <b-field label="הזן שם שלישי לוידאו">
                        <b-input v-model="model.name3"></b-input>
                    </b-field>
                    <b-field label="הזן שם רביעי לוידאו">
                        <b-input v-model="model.name4"></b-input>
                    </b-field>
                    <b-field label="לינק ראשון לוידאו">
                        <b-input v-model="model.link1"></b-input>
                    </b-field>
                    <b-field label="לינק שני לוידאו">
                        <b-input v-model="model.link2"></b-input>
                    </b-field>
                    <b-field label="לינק שלישי לוידאו">
                        <b-input v-model="model.link3"></b-input>
                    </b-field>
                    <b-field label="תצוגה">
                        <b-input v-model="model.thumbnailUrl"></b-input>
                    </b-field>
                    <b-field label="HTML">
                        <b-input v-model="model.htmlContent"></b-input>
                    </b-field>
                    <b-field label="משך הוידאו">
                        <b-input type="number" v-model="model.durationInSeconds"></b-input>
                    </b-field>
                    <b-field label="אינטרקטיבי">
                        <b-checkbox style="gap: 10px" v-model="model.isInteractiveVideo" label=""
                            type="is-link is-light" class="checkbox">
                            {{ model.isInteractiveVideo ? ' כן' : 'לא' }}
                        </b-checkbox>
                    </b-field>
                    <b-field label="סטטוס">
                        <b-checkbox style="gap: 10px" v-model="model.status" label="" type="is-link is-light"
                            class="checkbox">
                            {{ model.status ? ' פעיל' : 'לא פעיל' }}
                        </b-checkbox>
                    </b-field>
                </div>
            </section>
            <footer class="modal-card-foot">
                <b-button :loading="isLoading" @click="save" label="שמור" type="is-primary" />
                <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
            </footer>
        </div>
    </div>
</template>

<script>
import AdminService from "@/services/AdminService";
import FieldInlineSelect from '@/components/Fields/FieldInlineSelect.vue';
import { ToastProgrammatic as Toast } from "buefy";
import CoursesService from "../services/CoursesService";

export default {
    name: "DialogReportDriverAssign",
    props: ["onSuccess", "store"],
    components: {
        // NewActivity,
        FieldInlineSelect
    },
    computed: {
    },
    data() {
        return {
            submitted: false,
            isLoading: false,
            loading: false,
            model: {
                id: null,
                name: null,
                name2: null,
                name3: null,
                name4: null,
                link1: null,
                link2: null,
                link3: null,
                htmlContent: null,
                status: true,
                isInteractiveVideo: null,
                thumbnailUrl: null,
                durationInSeconds: null,
                lessonCategoryId: null
            }
        };
    },
    methods: {
        save() {
            this.submitted = true;
            if (this.model.name) {
                this.isLoading = true;
                AdminService.addVideoInBank(this.model)
                    .then(() => {
                        // this.reports.map((t, idx) => (
                        //     t.activities.unshift(r[idx].data)
                        // ));
                        this.$emit("close");
                        if (this.onSuccess) this.onSuccess();
                        window.location.reload();
                        Toast.open({
                            type: "is-success",
                            message: "הפעולה בוצעה!",
                            duration: 4000,
                        });
                    }).catch(() => {
                        Toast.open({
                            type: "is-danger",
                            message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
                            duration: 8000,
                        });
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            }
        },
        getCategories() {
            return () =>
                CoursesService.getCategories().then((r) =>
                    r.data.map((i) => ({ Value: i.id, Text: i.name }))
                );
        },
    },
};
</script>

<style lang="scss"></style>
