var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Quotes" }, [
    _c("div", { staticClass: "Quotes__Header" }),
    _c(
      "div",
      { staticClass: "loading" },
      [
        _c("b-loading", {
          attrs: { "is-full-page": true, "can-cancel": false },
          model: {
            value: _vm.isLoading,
            callback: function($$v) {
              _vm.isLoading = $$v
            },
            expression: "isLoading"
          }
        })
      ],
      1
    ),
    _c("div", { staticClass: "Quotes__Content" }, [
      _c("div", { staticClass: "right" }, [
        _c(
          "div",
          { staticClass: "flex space-between items-center" },
          [
            _c(
              "b-field",
              {
                staticStyle: { width: "100%" },
                attrs: { label: "חפש פסק דין" }
              },
              [
                _c("b-input", {
                  staticStyle: { "max-width": "30%" },
                  attrs: { type: "number" },
                  nativeOn: {
                    keydown: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.searchVerdict($event)
                    }
                  },
                  model: {
                    value: _vm.documentId,
                    callback: function($$v) {
                      _vm.documentId = $$v
                    },
                    expression: "documentId"
                  }
                }),
                _c(
                  "b-button",
                  { staticClass: "close", on: { click: _vm.searchVerdict } },
                  [_vm._v("חפש")]
                ),
                _vm.quoteHrefs.length
                  ? _c(
                      "v-select",
                      {
                        staticStyle: {
                          height: "40px",
                          "max-width": "45%",
                          width: "100%"
                        },
                        attrs: {
                          dir: "rtl",
                          label: "Text",
                          options: _vm.quoteHrefs,
                          clearable: false,
                          multiple: false,
                          "close-on-select": true,
                          placeholder: "ההפניה הבאה"
                        },
                        on: { input: _vm.nextVerdict },
                        model: {
                          value: _vm.quoteHref,
                          callback: function($$v) {
                            _vm.quoteHref = $$v
                          },
                          expression: "quoteHref"
                        }
                      },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "no-options" }, slot: "no-options" },
                          [_vm._v("לא נמצאו ערכים דומים")]
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            ),
            _vm.verdictFile
              ? _c(
                  "div",
                  { staticClass: "Quotes__Footer" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "close",
                        staticStyle: {
                          "text-align": "center",
                          "justify-content": "center",
                          height: "45px"
                        },
                        attrs: { disabled: _vm.rightHtml },
                        on: { click: _vm.connectRightQuote }
                      },
                      [_vm._v("סמן ציטוט")]
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        ),
        _vm.verdictFile
          ? _c("div", { staticClass: "right__content" }, [
              _c("div", {
                staticStyle: { position: "relative" },
                attrs: { id: "quoter-html" },
                domProps: { innerHTML: _vm._s(_vm.verdictFile) }
              })
            ])
          : _vm._e()
      ]),
      _c("div", { staticClass: "left" }, [
        _c(
          "div",
          {
            staticClass: "flex space-between items-center",
            staticStyle: { gap: "10px" }
          },
          [
            _vm.quotes.length
              ? _c(
                  "b-field",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { label: "פסקי דין מקושרים" }
                  },
                  [
                    _c(
                      "v-select",
                      {
                        staticStyle: { height: "40px", "max-width": "100%" },
                        attrs: {
                          dir: "rtl",
                          label: "Text",
                          options: _vm.quotes,
                          clearable: false,
                          multiple: false,
                          searchable: true,
                          "close-on-select": true,
                          placeholder: "בחר ערך מהרשימה"
                        },
                        on: { input: _vm.onChange },
                        model: {
                          value: _vm.selectedQuote,
                          callback: function($$v) {
                            _vm.selectedQuote = $$v
                          },
                          expression: "selectedQuote"
                        }
                      },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "no-options" }, slot: "no-options" },
                          [_vm._v("לא נמצאו ערכים דומים")]
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.quoteFile
              ? _c(
                  "div",
                  { staticClass: "Mark__Container" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "close",
                        staticStyle: {
                          "text-align": "center",
                          "justify-content": "center",
                          height: "45px"
                        },
                        attrs: { disabled: !_vm.hashedQuotes },
                        on: { click: _vm.connectLeftQuote }
                      },
                      [_vm._v("סמן ציטוט")]
                    ),
                    _c(
                      "b-button",
                      {
                        staticClass: "show-entity-btn",
                        attrs: { disabled: !_vm.rightHtml || !_vm.leftHtml },
                        on: { click: _vm.save }
                      },
                      [_vm._v("שמור")]
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        ),
        _vm.quoteFile
          ? _c("div", { staticClass: "left__content" }, [
              _c("div", {
                attrs: { id: "quoted-html" },
                domProps: { innerHTML: _vm._s(_vm.quoteFile) }
              })
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }