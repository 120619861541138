<template>
  <div class="Profile__Page">
    <div v-if="isInQuotesPage" :style="[!isModal && { 'right': 0, 'margin-right': '5px' }]" @click="setAdminModal" class="Admin__Close__Modal">
      <b-icon style="color: #2e3f50;" :icon="`${isModal ? 'arrow-right' : 'arrow-left'}`" size="is-medium"></b-icon>
    </div>
    <transition name="fade">
      <div v-if="isModal" class="Profile__Page__Menu">
        <h1>אדמיניסטרציה</h1>
        <b-menu>
          <b-menu-list>
            <b-menu-item v-if="!isQuoter" label="ניהול קורסים" tag="router-link" to="/admin/profiles"></b-menu-item>
            <b-menu-item v-if="!isQuoter" label="ניהול משתמשים" tag="router-link" to="/admin/users"></b-menu-item>
            <b-menu-item v-if="!isQuoter" label="בנק שאלות" tag="router-link" to="/admin/questionsbank"></b-menu-item>
            <b-menu-item v-if="!isQuoter" label="בנק וידאו" tag="router-link" to="/admin/videosbank"></b-menu-item>
            <b-menu-item v-if="!isQuoter" label="בנק ספרים" tag="router-link" to="/admin/booksbank"></b-menu-item>
            <b-menu-item v-if="isQuoter" label="ציטוטים" tag="router-link" to="/admin/quotes"></b-menu-item>
            <b-menu-item v-if="!isQuoter" label="קופונים" tag="router-link" to="/admin/coupons"></b-menu-item>
            <b-menu-item v-if="!isQuoter">
              <template #label="props">
                פעולות
                <b-icon class="is-pulled-left" :icon="props.expanded ? 'menu-up' : 'menu-down'"></b-icon>
              </template>
              <b-menu-item label="עדכון חוקים חסרים לתרגולים" tag="router-link"
                to="/admin/action/updateexerciselaws"></b-menu-item>
              <b-menu-item label="עריכת מקבצי חקיקה" tag="router-link"
                to="/admin/action/updatelawcollections"></b-menu-item>
              <b-menu-item label="שכפול יחידה" tag="router-link" to="/admin/action/unitReplication"></b-menu-item>
              <b-menu-item label="ניהול תקצירי פסיקה" tag="router-link"
                to="/admin/action/verdictReplication"></b-menu-item>
              <b-menu-item label="יצירת קובץ תשובות" tag="router-link"
                to="/admin/action/createanswersfiles"></b-menu-item>
              <b-menu-item label="עריכת מקבצי פסיקה" tag="router-link"
                to="/admin/action/verdictscollections"></b-menu-item>
              <b-menu-item label="ניהול רשימת חוקים" tag="router-link"
                to="/admin/action/manageLawsCollections"></b-menu-item>
              <b-menu-item label="עדכון אורכים ותמונות חסרות לוידאו" tag="router-link"
                to="/admin/action/updateVideoContent"></b-menu-item>
            </b-menu-item>
          </b-menu-list>
        </b-menu>
      </div>
    </transition>
    <div class="Profile__Page__Content">
      <router-view v-if="ready" />
    </div>
  </div>
</template>

<script>
// import vSelect from "vue-select";
import "vue-select/src/scss/vue-select.scss";
import ReportService from "@/services/ReportService";
import ServicesService from "@/services/ServicesService";
import store from '../store';

const reportsOptions = [
  { Value: 3, Text: 'הכל' },
  { Value: 1, Text: 'הסבת חברות' },
  { Value: 2, Text: 'הסבת נהגים' }
];

export default {
  name: "Profile",

  async created() {
    this.ready = true;
    // this.startHelathCheck();
    if (this.profileId) {
      store.commit("auth/setCourse", null);
      window.location.href = this.$router.resolve({ name: "admin" }).href;
    }
  },
  components: {
    // vSelect
  },
  computed: {
    isAdmin() {
      return this.$store.state.auth?.user?.claims?.isAdmin === "True";
    },
    isInQuotesPage() {
      return this.$route.name === "AdminQuotes";
    },
    profileId() {
      return this.$store.state.auth.course?.id;
    },
    isQuoter() {
      return this.$store.state.auth.role === "Quoter";
    }
  },
  data() {
    return {
      ready: false,
      isHealthy: null,
      reportsType: reportsOptions[0],
      reportsOptions,
      isModal: true
    };
  },
  methods: {
    updateExerciseLaws() {
      console.log('ss');
    },
    setAdminModal() {
      this.isModal = !this.isModal;
    },
    startHelathCheck() {
      setInterval(() => {
        this.checkHealth().then((r) => {
          this.isHealthy = r;
        });
      }, 15000);
    },
    async checkHealth() {
      try {
        await ServicesService.healthCheck();
        return true;
      } catch {
        return false;
      }
    },
    async startTransmittion() {
      const isHealthy = await this.checkHealth();
      if (isHealthy) {
        this.$buefy.dialog.confirm({
          title: "שידור דוחות",
          message: "האם לתהחיל שידור דוחות?",
          confirmText: "כן",
          cancelText: "לא",
          type: "is-warrent",
          closeOnConfirm: true,
          hasIcon: true,
          onConfirm: (a, instance) => {
            const type = this.reportsType && this.reportsType.Value !== 3 ? this.reportsType.Value : undefined;
            ReportService.sendAllTransfers(type).then(() => {
              instance.close();
              this.$buefy.toast.open({
                type: "is-success",
                message: "השידור החל...",
                position: "is-top",
                duration: 4000,
              });
            });
          },
        });
      } else {
        this.$buefy.toast.open({
          type: "is-danger",
          message: "שירות שידור אינו פעיל!",
          position: "is-top",
          duration: 8000,
        });
      }
    },
    cancelTransmittion() {
      ReportService.cancelAllTransfers().then(() => {
        this.$buefy.toast.open({
          type: "is-info",
          message: "השידור הפסיק...",
          position: "is-top",
          duration: 8000,
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.Profile__Page {
  display: flex;

  .Admin__Close__Modal {
    position: absolute;
    right: 285px;
    cursor: pointer;
    display: flex;
    border-radius: 50%;
    border: 1px solid black;
    background-color: #f5f8fa;
    margin-top: 10px;
    z-index: 20;
  }

  .Profile__Page__Menu {
    flex-basis: 300px;
    background-color: #f5f8fa;
    min-height: calc(100vh - 50px);
    padding: 20px;

    h1 {
      font-size: 25px;
      padding: 5px 10px;
    }

    ::v-deep a {
      color: inherit;
      padding: 10px 10px;
      font-size: 16px;
      width: 100%;
      display: block;
      border-radius: 3px;

      &.router-link-exact-active {
        background-color: #eaf0f6;
        font-weight: 500;
      }

      &:hover {
        background-color: #eaf0f6;
      }
    }
  }

  .Profile__Page__Content {
    flex: 1;
    border-right: 1px solid #c0c0c09e;
    overflow-x: auto;
    width: calc(100% - 300px);
  }
}

::v-deep .menu-list a {
  text-decoration: auto;
}

::v-deep {
  .menu-list a.is-active {
    background: inherit;
  }
}
</style>
