<template>
  <div class="Municipality__Entity">
    <MunicipalityEntityDetails
      :is-open="true"
      :item="id"
      :entityId="id.EntityId"
    />
  </div>
</template>

<script>
import MunicipalityEntityDetails from "@/components/MunicipalityEntityDetails.vue";

export default {
  name: "MunicipalityEntity",
  props: ["id"],
  data() {
    return {
    };
  },
  components: {
    MunicipalityEntityDetails,
  },
};
</script>

<style scoped lang="scss">
.Task__Entity__Related__Item {
  padding: 10px;

  span {
    font-size: 16px;
  }

  .Task__Entity__Related__Item__TheItem {
    border: 2px solid #00b0b2;
    padding: 10px;
    margin: 15px;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
      border-color: #0aced0 !important;
    }
  }
}
.ActivityItemTask__Component {
  ::v-deep {
    .Activity__Item__Box__Content__Text__Title {
      padding-top: 15px;
    }
    .NewTask__Component__Reminder__Fields {
      flex-direction: column;

      & > div {
        width: 100%;
        margin: 0 !important;
      }
    }
    .NewTask__Component__Attributes {
      flex-direction: column;
      & > div {
        margin: 0 !important;
      }
    }
    .Activity__Item__Box__Content__Text__Editor__Wrapper {
      padding: 0 20px;
    }
    .NewTask__Component__Editor_Inner {
      padding: 0px;
      border: 2px solid #2fc9e8;
      border-radius: 3px;
      margin-bottom: 10px;

      .ProseMirror {
        min-height: 100px;
      }
    }
  }
}
</style>
