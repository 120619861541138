<template>
  <div class="Reports__View">
    <EntityListLayout :tabs="tabs" ref="entity" @onNewEntitySave="onNewEntitySave">
      <template #title>בנק שאלות</template>
      <template #button>
        <!-- <b-button @click="showNewEntity">הוספת דוח חדש</b-button> -->
        <a @click="addQuestion" class="link-button">
          שאלה חדשה
        </a>
      </template>
      <template v-slot:counter="slotProps">
        סה"כ <b>{{ getTotalRecords(slotProps) }} </b>
      </template>
    </EntityListLayout>
  </div>
</template>
<script>
import AdminService from "@/services/AdminService";
import EntityListLayout from "@/components/EntityListLayout.vue";
// import DialogAddTask from '@/components/DialogAddTask.vue';
// import { ModalProgrammatic as Modal } from 'buefy';
// import TableActions from "@/services/TableActionsService";
import FieldService from "@/services/FieldService";
import FilterService from "@/services/FilterService";
import NewReportEntity from "@/components/Entities/NewReportEntity.vue";
import { saveAs } from "file-saver";
import QuestionEntity from "./QuestionEntity.vue";

export default {
  name: "AdminSearchReports",
  data() {
    return {
      tabs: [],
    };
  },
  components: {
    EntityListLayout,
  },
  computed: {
    isAdmin() {
      return this.$store.state.auth?.user?.claims?.isAdmin === "True";
    },
    tabName() {
      return this.selectedTab && this.selectedTab.title;
    },
    excelMimeType() {
      return "application/vnd.ms-excel";
    },
    isLeasingCompany() {
      return this.$store.state.profile?.IsLeasingCompany;
    },
    isReady() {
      return this.isLeasingCompany === null;
    },
  },
  created() {
    this.create();
  },
  methods: {
    create() {
      const reportsFields = FieldService.list("questions");
      const reportsfilters = FilterService.list();

      const sideViewEntity = {
        component: QuestionEntity,
        preview: true,
        readOnly: false,
        title: "פרטי שאלה",
        recordTitle: 'הצג שאלה',
        recordUrl: "question",
      };

      const excelExport = [
        {
          title: "יצוא טבלה",
          action: this.export,
        },
        // {
        //   title: "יצוא מקובץ לפי עיריות",
        //   action: this.exportByMunic,
        // },
        // {
        //   title: "יצוא מקובץ לפי לקוח",
        //   action: this.exportByClient,
        // },
      ];
      const fields = [
        { ...reportsFields.Id, checked: true },
        { ...reportsFields.Question, checked: true },
        { ...reportsFields.EditorNotes, checked: true },
        { ...reportsFields.Entity, checked: true },
      ];

      this.tabs = [
        {
          id: "AllQuestionsInBank",
          title: "כל השאלות",
          newEntityComponent: NewReportEntity,
          getDataAsync: this.getAllQuestions,
          perPage: 20,
          fields,
          filters: [
          { ...reportsfilters.ExerciseId },
          { ...reportsfilters.AcademicInstituteId },
          { ...reportsfilters.AuthorId },
          { ...reportsfilters.QuestionCategoryIds },
          { ...reportsfilters.LawId },
          // { ...reportsfilters.LawClauseId },
          { ...reportsfilters.MinimumStudentAnswers },
          { ...reportsfilters.HasOpenRequestsOnly },
          { ...reportsfilters.IsActive, selected: true },
          { ...reportsfilters.IsInactive },
          { ...reportsfilters.HasEditorNotesOnly },
          { ...reportsfilters.MinimumDifficulty },
          { ...reportsfilters.MaximumDifficulty },
          { ...reportsfilters.SearchFilterType },
          ],
          sortField: "LastPayDate",
          sortOrder: "desc",
          checkableRows: false,
          excelExport,
          fastSearch: true,
          fastSearchOptions: {
            propName: "Terms",
            label: "חיפוש שאלה בבנק",
          },
          sideViewEntity,
          tableActions: null,
          isAdminArea: true,
          defaultQuery: false
          // defaultQuery: {
          //   IsAscending: false,
          //   SortedBy: "LastPayDate"
          // }
        },
      ];
    },
    async onPlateNumberClick(context) {
      context.default();
    },
    showNewEntity() {
      this.$refs.entity.newEntityMenu();
    },
    onNewEntitySave() {
      this.$refs.entity.onNewEntityCancel();
    },
    export({ loading, query }) {
      loading(true);
      AdminService.exportXslAdmin(query)
        .then((r) => {
          this.saveFile(r.data, "יצוא דוחות ממערכת Betterway");
        })
        .finally(() => {
          loading(false);
        });
    },
    exportByMunic({ loading, query }) {
      loading(true);
      AdminService.exportXsl(query, "Municipalities")
        .then((r) => {
          this.saveFile(r.data, "יצוא דוחות ממערכת Betterway מקובץ לפי עיריות");
        })
        .catch(() => { })
        .finally(() => {
          loading(false);
        });
    },
    exportByClient({ loading, query }) {
      loading(true);
      AdminService.exportXsl(query, "Profiles")
        .then((r) => {
          this.saveFile(r.data, "יצוא דוחות ממערכת Betterway מקובץ לפי לקוחות");
        })
        .catch(() => { })
        .finally(() => {
          loading(false);
        });
    },
    getAllQuestions({ cancelToken, ...rest }) {
      return AdminService.getQuestionsInBank(
        {
          ...rest,
        },
        cancelToken.token
      ).then((r) => ({
        data: {
          TotalResults: r.data.totalResults,
          Items: r.data.items,
        },
      }));
    },
    getAllTransfers({ cancelToken, ...rest }) {
      return AdminService.adminSearchTransfers(
        {
          ...rest,
        },
        cancelToken.token
      );
    },
    getPayments({ cancelToken }) {
      return AdminService.getPayments(cancelToken.token).then((r) => ({
        data: {
          TotalResults: r.data.length,
          Items: r.data,
        },
      }));
    },
    getTotalRecords(slotProps) {
      return slotProps.tab && slotProps.tab.total && slotProps.tab.total;
    },
    saveFile(data, filename) {
      const blob = new Blob([data], { type: this.excelMimeType });
      saveAs(blob, `${filename}.xlsx`);
    },
    checkFields(fields, idxFieldToCheck) {
      const newFields = [...fields];
      newFields.splice(idxFieldToCheck, 1, {
        ...newFields[idxFieldToCheck],
        checked: true,
      });
      return newFields;
    },
    displayFields(fields, fieldsDisplayTrueArr, fieldsDisplayFalseArr) {
      const newFields = [...fields];
      newFields.forEach((field, idx) => {
        if (fieldsDisplayTrueArr.includes(field.Display)) {
          newFields.splice(idx, 1, {
            ...newFields[idx],
            checked: true,
          });
        }
        if (fieldsDisplayFalseArr.includes(field.Display)) {
          newFields.splice(idx, 1, {
            ...newFields[idx],
            checked: false,
          });
        }
      });
      return newFields;
    },
    addQuestion() {
      this.$router.push('/newQuestion');
      // Modal.open({
      //   component: DialogAddTask,
      //   props: {
      //     reportNumbers: [{ Id: 1, reportNumber: 123 }, { Id: 2, reportNumber: 3456 }],
      //     store: this.$store
      //   },
      //   canCancel: false,
      //   hasModalCard: true,
      //   trapFocus: true,
      // });
    }
  },
  watch: {
    isReady(value) {
      if (value) {
        this.create();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.Reports__View {
  height: 100%;

  .link-button {
    background: #ff7a59;
    border: none;
    border-radius: 3px;
    padding: 14px 25px;
    margin-left: 10px;
    color: white;
    cursor: pointer;
  }
}
</style>
