var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "TableSelectable__Component" }, [
    _c("div", { staticClass: "table-scrollable" }, [
      _vm.slicedData
        ? _c(
            "table",
            { staticClass: "table" },
            [
              _vm.headersModel
                ? _c(
                    "tr",
                    [
                      _vm.showRowNumber
                        ? _c("th", { staticClass: "row-number" }, [_vm._v("#")])
                        : _vm._e(),
                      _vm._l(_vm.headersModel, function(header, headerIndex) {
                        return _c(
                          "th",
                          { key: headerIndex },
                          [
                            _c(
                              "b-select",
                              {
                                ref: "select_" + headerIndex,
                                refInFor: true,
                                attrs: { placeholder: "בחר עמודה" },
                                on: {
                                  input: function($event) {
                                    return _vm.onSelect(
                                      header,
                                      headerIndex,
                                      $event
                                    )
                                  }
                                }
                              },
                              [
                                _c("option", { attrs: { value: "$reset" } }, [
                                  _vm._v("בחר עמודה")
                                ]),
                                _vm._l(header, function(option) {
                                  return _c(
                                    "option",
                                    {
                                      key: option.Name,
                                      class: {
                                        "is-required": option.IsRequired
                                      },
                                      attrs: {
                                        disabled: _vm.isSelected(option)
                                      },
                                      domProps: { value: option }
                                    },
                                    [_vm._v(" " + _vm._s(option.Name) + " ")]
                                  )
                                })
                              ],
                              2
                            )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                : _vm._e(),
              _vm._l(_vm.slicedData, function(item, index) {
                return _c(
                  "tr",
                  {
                    key: index,
                    class: {
                      ignore: index < _vm.startingRow,
                      "is-error": !_vm.isRowValid(index)
                    }
                  },
                  [
                    _vm.showRowNumber
                      ? _c("td", { staticClass: "row-number" }, [
                          _vm._v(_vm._s(index + 1))
                        ])
                      : _vm._e(),
                    _vm._l(item, function(value, valueIndex) {
                      return _c(
                        "td",
                        {
                          key: valueIndex,
                          class: {
                            "is-header": !index,
                            "is-danger": !_vm.isCellValid(index, valueIndex)
                          }
                        },
                        [_vm._v(" " + _vm._s(value) + " ")]
                      )
                    })
                  ],
                  2
                )
              })
            ],
            2
          )
        : _c(
            "table",
            { staticClass: "table" },
            [
              _vm.headersModel
                ? _c(
                    "tr",
                    [
                      _vm.showRowNumber
                        ? _c("th", { staticClass: "row-number" }, [_vm._v("#")])
                        : _vm._e(),
                      _vm._l(_vm.headersModel, function(header, headerIndex) {
                        return _c(
                          "th",
                          { key: headerIndex },
                          [
                            _c(
                              "b-select",
                              {
                                ref: "select_" + headerIndex,
                                refInFor: true,
                                attrs: { placeholder: "בחר עמודה" },
                                on: {
                                  input: function($event) {
                                    return _vm.onSelect(
                                      header,
                                      headerIndex,
                                      $event
                                    )
                                  }
                                }
                              },
                              [
                                _c("option", { attrs: { value: "$reset" } }, [
                                  _vm._v("בחר עמודה")
                                ]),
                                _vm._l(header, function(option) {
                                  return _c(
                                    "option",
                                    {
                                      key: option.Name,
                                      class: {
                                        "is-required": option.IsRequired
                                      },
                                      attrs: {
                                        disabled: _vm.isSelected(option)
                                      },
                                      domProps: { value: option }
                                    },
                                    [_vm._v(" " + _vm._s(option.Name) + " ")]
                                  )
                                })
                              ],
                              2
                            )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                : _vm._e(),
              _vm._l(_vm.data, function(item, index) {
                return _c(
                  "tr",
                  {
                    key: index,
                    class: {
                      ignore: index < _vm.startingRow,
                      "is-error": !_vm.isRowValid(index)
                    }
                  },
                  [
                    _vm.showRowNumber
                      ? _c("td", { staticClass: "row-number" }, [
                          _vm._v(_vm._s(index + 1))
                        ])
                      : _vm._e(),
                    _vm._l(item, function(value, valueIndex) {
                      return _c(
                        "td",
                        {
                          key: valueIndex,
                          class: {
                            "is-header": !index,
                            "is-danger": !_vm.isCellValid(index, valueIndex)
                          }
                        },
                        [_vm._v(" " + _vm._s(value) + " ")]
                      )
                    })
                  ],
                  2
                )
              })
            ],
            2
          )
    ]),
    _c("div", { staticClass: "indicators" }, [
      _c("div", { staticClass: "total-rows" }, [
        _vm._v("כמות שורות: " + _vm._s(_vm.totalRows))
      ]),
      _c("div", { class: { "total-errors": _vm.rowsWithErrors } }, [
        _vm._v(" שורות שגויות: " + _vm._s(_vm.rowsWithErrors) + " ")
      ]),
      _c("div", { class: { "total-to-import": _vm.rowsToImport } }, [
        _vm._v(" תקינים: " + _vm._s(_vm.rowsToImport) + " ")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }