<template>
  <div class="Tasks__View">
    <EntityListLayout :tabs="tabs">
      <template #title>משימות</template>
      <template v-slot:counter="slotProps">
        סה"כ <b>{{ getTotalRecords(slotProps) }} </b>
      </template>
    </EntityListLayout>
  </div>
</template>
<script>
import EntityItemsService from "@/services/EntityItemsService";
import EntityListLayout from "@/components/EntityListLayout.vue";
import TaskEntity from "@/views/TaskEntity.vue";
import TableActions from "@/services/TableActionsService";
import FieldService from "@/services/FieldService";
import FilterService from "@/services/FilterService";
// import ProfileService from "@/services/ProfileService";

export default {
  name: "Tasks",
  data() {
    return {
      tabs: [],
      currProfileInTasks: null,
    };
  },
  components: {
    EntityListLayout,
  },
  computed: {
    isAdmin() {
      return this.$store.state.auth?.user?.claims?.isAdmin === "True";
    },
    tabName() {
      return this.$route.query.tab || "AllTasks";
    },
  },
  created() {
    const taskFields = FieldService.list("tasks");
    const tasksFilters = FilterService.list();

    const sideViewEntity = {
      component: TaskEntity,
      preview: true,
      readOnly: true,
      title: "פרטי משימה",
      map: (item) => item,
      hideRecord: true,
    };

    const fields = [
      { ...taskFields.AssignedUserName, checked: true },
      { ...taskFields.AssignedClientProfile, checked: true },
      { ...taskFields.AssignedDriverProfile, checked: true },
      { ...taskFields.AssignedOwnerProfile, checked: true },
      { ...taskFields.DueDate, checked: true },
      { ...taskFields.EmailReminderDate, checked: true, Sortable: true },
      { ...taskFields.Priority, checked: true },
      { ...taskFields.EntityType, checked: true },
      { ...taskFields.IsOverdue, checked: false },
      { ...taskFields.IsComplete, checked: true },
      { ...taskFields.CompletionDate, checked: false },
      { ...taskFields.CreatorName, checked: false },
      { ...taskFields.Title, checked: true },
      { ...taskFields.Body, checked: false },
    ];

    const tab = {
      id: "AllTasks",
      title: "כל המשימות",
      getDataAsync: this.getAllTasks,
      perPage: 20,
      // fastSearch: true,
      // fastSearchOptions: {
      //   propName: "Title",
      //   label: "חיפוש לפי כותרת",
      // },
      fields,
      checkableRows: true,
      filters: [
        {
          ...tasksFilters.AssigneeUserId,
          // eslint-disable-next-line no-confusing-arrow
          selected: async () =>
            // eslint-disable-next-line no-return-await
            this.isAdmin ? "" : await this.getProfileInTasks(),
        },
        { ...tasksFilters.TaskType },
        { ...tasksFilters.Priority },
        { ...tasksFilters.SearchFilterType },
      ],
      sortField: "EmailReminderDate",
      sortOrder: "asc",
      sideViewEntity,
      tableActions: (() => {
            const actions = [
              TableActions.updateTask,
            ];
            return actions;
          })(),
    };

    this.tabs = [
      tab,
      {
        ...tab,
        id: "DueTodayTasks",
        title: "מסתיימות היום",
        getDataAsync: ({ cancelToken, setPermanentQuery, ...rest }) => {
          const query = rest;
          query.SearchFilterType = 1;
          setPermanentQuery({
            SearchFilterType: 1,
          });
          return this.getAllTasks({ cancelToken, ...query });
        },
      },
      {
        ...tab,
        id: "DueDueTasks",
        title: "משימות חורגות",
        getDataAsync: ({ cancelToken, setPermanentQuery, ...rest }) => {
          const query = rest;
          query.SearchFilterType = 2;
          setPermanentQuery({
            SearchFilterType: 2,
          });
          return this.getAllTasks({ cancelToken, ...query });
        },
      },
      {
        ...tab,
        id: "UpcomingTasks",
        title: "משימות חדשות",
        getDataAsync: ({ cancelToken, setPermanentQuery, ...rest }) => {
          const query = rest;
          query.SearchFilterType = 3;
          setPermanentQuery({
            SearchFilterType: 3,
          });
          return this.getAllTasks({ cancelToken, ...query });
        },
      },
      {
        ...tab,
        id: "CompletedTasks",
        title: "משימות שהסתיימו",
        getDataAsync: ({ cancelToken, setPermanentQuery, ...rest }) => {
          const query = rest;
          query.SearchFilterType = 4;
          setPermanentQuery({
            SearchFilterType: 4,
          });
          return this.getAllTasks({ cancelToken, ...query });
        },
      },
    ];
  },
  methods: {
    // async getProfileInTasks() {
    //   if (!this.currProfileInTasks) {
    //     const r = await ProfileService.adminUsersTask();
    //     this.currProfileInTasks = null;
    //   }
    //   return this.currProfileInTasks;
    // },
    async getAllTasks({ cancelToken, ...rest }) {
      // rest.SortedBy = 'DueDate';
      // rest.IsAscending = true;
      // const userId = await this.getProfileInTasks();
      let query = {};
      if (typeof rest.AssigneeUserId !== "undefined") {
        query = {};
        const currTab = this.tabs.find((tab) => tab.id === this.tabName);
        const currFilter = currTab.filters.find(
          (f) => f.Query === "AssigneeUserId"
        );
        currFilter.selected = null;
      } // check if filter got clean in porpose or its the default filter
      return EntityItemsService.tasks(
        {
          SearchFilterType: 0,
          ...rest,
          ...query,
        },
        cancelToken.token
      );
    },
    getTotalRecords(slotProps) {
      return slotProps.tab && slotProps.tab.total && slotProps.tab.total;
    },
  },
};
</script>

<style scoped>
.Tasks__View {
  height: 100%;
}
</style>
