<template>
  <div class="Vehicles__View">
    <EntityListLayout
      ref="entity"
      :tabs="tabs"
      @onNewEntitySave="onNewEntitySave"
    >
      <template #title>רכבים</template>
      <template #button v-if="isAdmin">
        <b-button @click="showNewEntity">הוספת רכב חדש</b-button>
      </template>
      <template v-slot:counter="slotProps">
        סה"כ <b>{{ getTotalRecords(slotProps) }} </b>
      </template>
    </EntityListLayout>
  </div>
</template>
<script>
import VehicleService from "@/services/VehicleService";
import EntityListLayout from "@/components/EntityListLayout.vue";
import AdminVehicleEntity from "@/views/AdminVehicleEntity.vue";
import FieldService from "@/services/FieldService";
import FilterService from "@/services/FilterService";
import ProfileService from "@/services/ProfileService";
import NewVehicleEntity from "@/components/Entities/NewVehicleEntity.vue";
import { saveAs } from "file-saver";

export default {
  name: "Reports",
  data() {
    return {
      tabs: [],
    };
  },
  components: {
    EntityListLayout,
  },
  computed: {
    tabName() {
      return this.selectedTab && this.selectedTab.title;
    },
    isAdmin() {
      return this.$store.state.auth?.user?.claims?.isAdmin === "True";
    },
  },
  created() {
    const vehicleFields = FieldService.list("vehicles");
    const vehiclefilters = FilterService.list();
    const { DateSearchTypes } = FilterService;

    const sideViewEntity = {
      component: AdminVehicleEntity,
      preview: true,
      readOnly: true,
      title: "פרטי רכב",
      recordUrl: "admin/vehicles",
    };
    this.tabs = [
      {
        id: "AllVehiclesAdmin",
        title: "רכבים מנוהלים",
        newEntityComponent: NewVehicleEntity,
        getDataAsync: this.getAllVehicles,
        perPage: 20,
        fields: [
          { ...vehicleFields.VehiclePlateNumber, checked: true, isAdmin: true },
          { ...vehicleFields.Manufacturer, checked: true },
          { ...vehicleFields.Model, checked: true },
          { ...vehicleFields.Year, checked: true },
          { ...vehicleFields.Color, checked: true },
          { ...vehicleFields.IsManaged, checked: true },
          { ...vehicleFields.Owner, checked: true },
          { ...vehicleFields.Profile, checked: true },
          { ...vehicleFields.StartContractDate, checked: true },
          { ...vehicleFields.EndContractDate, checked: true },
          { ...vehicleFields.ProfitCenter, checked: true },
        ],
        filters: [
          {
            ...vehiclefilters.Profile,
            Type: "FilterMultiDropdown",
            Query: "ProfileIds",
            isManagedOnly: true,
            OptionsAsync: () =>
              ProfileService.all().then((r) =>
                r.data.map((i) => ({
                  Value: i.Id,
                  IsManaged: i.IsManaged,
                  Text: i.Name,
                }))
              ),
          },
          {
            ...vehiclefilters.PlateNumber,
            OptionsAsync: (term) =>
              VehicleService.searchAdminPlateNumber(term).then((r) =>
                r.data.map((s) => ({ Text: s.Name, Value: s.Name }))
              ),
          },
          { ...vehiclefilters.Other },
          {
            ...vehiclefilters.DateTypes,
            Options: [{ ...DateSearchTypes.VehicleCustomerContract }],
          },
        ],
        fastSearch: true,
        sortField: "PlateNumber",
        sortOrder: "desc",
        excelExport: [
          {
            title: "יצוא טבלה",
            action: this.export,
          },
        ],
        sideViewEntity,
      },
    ];
  },
  methods: {
    showNewEntity() {
      this.$refs.entity.newEntityMenu();
    },
    onNewEntitySave() {
      this.$refs.entity.onNewEntityCancel();
    },
    exportUnmanaged({ loading }) {
      loading(true);
      VehicleService.unmanagedXls()
        .then((r) => {
          this.saveFile(r.data, "יצוא רכבים לא מנוהלים ממערכת Betterway");
        })
        .catch(() => {})
        .finally(() => {
          loading(false);
        });
    },
    export({ loading, query }) {
      loading(true);
      VehicleService.exportAllXls(query)
        .then((r) => {
          let extention = "xlsx";
          if (r.headers["content-type"] === "text/csv") extention = "csv";
          this.saveFile(
            r.data,
            "יצוא רכבים מנוהלים ממערכת Betterway",
            extention
          );
        })
        .catch(() => {})
        .finally(() => {
          loading(false);
        });
    },
    getAllVehicles({ cancelToken, ...rest }) {
      return VehicleService.allAdmin(
        {
          ...rest,
        },
        cancelToken.token
      );
    },
    getUnmanaged({ cancelToken }) {
      return VehicleService.unmanaged(cancelToken.token).then((r) => ({
        data: {
          TotalResults: r.data.length,
          Items: r.data,
        },
      }));
    },
    getTotalRecords(slotProps) {
      return slotProps.tab && slotProps.tab.total && slotProps.tab.total;
    },
    saveFile(data, filename, extention = "xlsx") {
      const blob = new Blob([data], { type: this.excelMimeType });
      saveAs(blob, `${filename}.${extention}`);
    },
  },
};
</script>
<style scoped>
.Vehicles__View {
  height: 100%;
}
</style>
