var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "New__Entity" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "SideMenu__Content" },
      [
        _c("FieldInlineText", {
          attrs: { label: "שם עיריה", required: _vm.$v.model.Name.$anyError },
          model: {
            value: _vm.$v.model.Name.$model,
            callback: function($$v) {
              _vm.$set(_vm.$v.model.Name, "$model", $$v)
            },
            expression: "$v.model.Name.$model"
          }
        }),
        _c("FieldInlineText", {
          attrs: { label: "כתובת", required: _vm.$v.model.Address.$anyError },
          model: {
            value: _vm.$v.model.Address.$model,
            callback: function($$v) {
              _vm.$set(_vm.$v.model.Address, "$model", $$v)
            },
            expression: "$v.model.Address.$model"
          }
        }),
        _c("FieldInlineSelect", {
          attrs: {
            label: 'חתימת עו"ד',
            required: _vm.$v.model.NeedLawyerSign.$anyError,
            options: [
              { Value: true, Text: "צריך" },
              { Value: false, Text: "לא צריך" }
            ],
            "null-is-value": "false"
          },
          model: {
            value: _vm.$v.model.NeedLawyerSign.$model,
            callback: function($$v) {
              _vm.$set(_vm.$v.model.NeedLawyerSign, "$model", $$v)
            },
            expression: "$v.model.NeedLawyerSign.$model"
          }
        }),
        _c("FieldInlineSelect", {
          attrs: {
            label: ' חתימת עו"ד לנהג',
            required: _vm.$v.model.NeedLawyerSignForDriver.$anyError,
            options: [
              { Value: true, Text: "צריך" },
              { Value: false, Text: "לא צריך" }
            ],
            "null-is-value": "false"
          },
          model: {
            value: _vm.$v.model.NeedLawyerSignForDriver.$model,
            callback: function($$v) {
              _vm.$set(_vm.$v.model.NeedLawyerSignForDriver, "$model", $$v)
            },
            expression: "$v.model.NeedLawyerSignForDriver.$model"
          }
        }),
        _c("FieldInlineText", {
          attrs: { label: "אימייל להסבה" },
          model: {
            value: _vm.$v.model.TransferEmail.$model,
            callback: function($$v) {
              _vm.$set(_vm.$v.model.TransferEmail, "$model", $$v)
            },
            expression: "$v.model.TransferEmail.$model"
          }
        }),
        _c("FieldInlineText", {
          attrs: { label: "לינק לתשלום" },
          model: {
            value: _vm.$v.model.PaymentUrl.$model,
            callback: function($$v) {
              _vm.$set(_vm.$v.model.PaymentUrl, "$model", $$v)
            },
            expression: "$v.model.PaymentUrl.$model"
          }
        }),
        _c("FieldInlineText", {
          attrs: {
            label: "TransferBotId",
            required: _vm.$v.model.TransferBotId.$anyError
          },
          model: {
            value: _vm.$v.model.TransferBotId.$model,
            callback: function($$v) {
              _vm.$set(_vm.$v.model.TransferBotId, "$model", $$v)
            },
            expression: "$v.model.TransferBotId.$model"
          }
        }),
        _c("FieldInlineText", {
          attrs: {
            label: "TransferBotAuthorityId",
            required: _vm.$v.model.TransferBotAuthorityId.$anyError
          },
          model: {
            value: _vm.$v.model.TransferBotAuthorityId.$model,
            callback: function($$v) {
              _vm.$set(_vm.$v.model.TransferBotAuthorityId, "$model", $$v)
            },
            expression: "$v.model.TransferBotAuthorityId.$model"
          }
        })
      ],
      1
    ),
    _c("div", { staticClass: "SideMenu__Footer" }, [
      _c(
        "div",
        [
          _c(
            "button",
            {
              staticClass: "close",
              attrs: { disabled: _vm.isLoading, type: "" },
              on: { click: _vm.onCancel }
            },
            [_vm._v(" ביטול ")]
          ),
          _c(
            "b-button",
            {
              staticClass: "create",
              attrs: { loading: _vm.isLoading, type: "" },
              on: { click: _vm.onSave }
            },
            [_vm._v(" שמור ")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "SideMenu__Header" }, [
      _c("h1", [_vm._v("הוספת עיריה")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }