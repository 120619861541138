<template>
  <div class="Admin__View">
    <div class="Admin__View__Profiles">
      <div class="is-flex is-justify-content-space-between is-align-items-center">
        <div class="is-flex">
          <div class="Field">
            <b-field label="חפש">
              <b-input v-model="term"></b-input>
            </b-field>
          </div>
          <b-field label="הצג קורסים פעילים">
            <b-switch v-model="isManaged" class="ltr pt-2">
              {{ isManaged ? 'כן' : 'לא' }}
            </b-switch>
          </b-field>
        </div>
        <!-- <b-button type="is-primary" @click="addProfile">הוספת פרופיל</b-button> -->
      </div>
      <div class="Admin__View__Profiles__Table">
        <b-table paginated :sticky-header="true" :data="data" :pagination-simple="true" :hoverable="true"
          :loading="isLoading" per-page="30" default-sort-direction="asc" :row-class="(row, index) => addRowClass(row)"
          default-sort="Id" @click="onRowClick" :mobile-cards="true">

          <b-table-column field="ןd" label="מזהה" v-slot="props" centered>
            {{ props.row.id }}
          </b-table-column>

          <b-table-column field="מame" label="שם הקורס" v-slot="props">
            {{ props.row.name }}
          </b-table-column>

          <b-table-column field="מame" label="שכפול קורס" v-slot="props">
            <div>
              <b-button @click.stop="duplicateCourse(props.row)">
                שכפל
                <b-icon style="padding: 25px" pack="fa" icon="copy"></b-icon>
              </b-button>
            </div>
          </b-table-column>

          <b-table-column field="מame" label="עריכת שם קורס" v-slot="props">
            <div>
              <b-button @click.stop="duplicateCourse(props.row, true)">
                ערוך
                <b-icon style="padding: 25px" pack="fa" icon="edit"></b-icon>
              </b-button>
            </div>
          </b-table-column>

          <!-- <b-table-column label="כתובת">
                יחידות הלימוד
              </b-table-column>

              <b-table-column label="מיקוד">
                משתמשים
              </b-table-column>

              <b-table-column field="LastCheckDate" cellClass="table-date" label="נבדק לאחרונה" sortable centered>
                מרכז פניות
              </b-table-column>

              <b-table-column field="IsLeasingCompany" label="הגדרות"
                 centered sortable>
                הגדרות
              </b-table-column> -->

        </b-table>
      </div>
    </div>
  </div>
</template>
<script>
import CoursesService from '@/services/CoursesService';
import AdminService from '@/services/AdminService';
import DialogDuplicateCourse from '@/components/DialogDuplicateCourse.vue';
import { mapMutations } from 'vuex';
import moment from 'moment';

export default {
  name: 'Admin',
  data() {
    return {
      isLoading: false,
      isManaged: true,
      data: [],
      origData: [],
      term: null,
    };
  },
  components: {

  },
  created() {
    this.search();
  },
  methods: {
    ...mapMutations('auth', ['setCourse']),
    duplicateCourse(course, isEdit) {
      this.$buefy.modal.open({
        component: DialogDuplicateCourse,
        canCancel: false,
        hasModalCard: true,
        trapFocus: true,
        props: {
          onSuccess: this.search,
          course: course,
          isEdit: isEdit
        },
      });
    },
    onRowClick(row) {
      this.setCourse(row);
      window.location.href = this.$router.resolve({ name: 'Lessons' }).href;
    },
    addRowClass(row) {
      let str = "";
      if (!row.status) str += "not-activated-row";
      return str;
    },
    parseDate(date) {
      return date ? moment(date).format("DD/MM/YYYY HH:mm") : '';
    },
    async search() {
      this.isLoading = true;
      const { data: courses } = await CoursesService.getCourses(this.isManaged);
      this.data = courses;
      this.origData = [...courses];
      this.isLoading = false;
    },
    getStatus(status) {
      switch (status) {
        case 1:
          return 'ממתין להרשמה';
        case 2:
          return 'ממתין לאישור';
        case 3:
          return 'פעיל';
        case 4:
          return 'משתמש חדש ממתין';
        default:
          return '';
      }
    },
    getAddress(row) {
      if (!row.City && !row.Street && !row.HouseNumber) return '';
      return `${row.City}, ${row.Street} ${row.HouseNumber}`;
    },
    filterByTerm(value = this.term) {
      this.data = this.origData
        .filter((i) => {
          const values = Object.values(i);
          return values.some((v) => (v
            && typeof v !== 'boolean'
            && typeof v !== 'number' ? !value || v.indexOf(value) > -1 : v === parseInt(value, 10)));
        });
    },
  },
  watch: {
    term(newValue) {
      this.filterByTerm(newValue);
    },
    async isManaged() {
      await this.search();
      this.filterByTerm();
    },
  },
};
</script>
<style scoped lang="scss">
.Admin__View {
  display: flex;
  flex-direction: column;
  padding: 20px 20px;
  height: 100%;

  .Admin__View__Profiles {
    height: 100%;

    .Field {
      padding: 0 15px 10px 15px;
      width: 300px;
    }

    .Admin__View__Profiles__Table {
      height: calc(100% - 130px);

      ::v-deep .table-date {
        direction: ltr;
        text-align: end;
      }

      ::v-deep {
        .b-table {
          height: 100%;

          .not-activated-row {
            background-color: LightGrey;

            a {
              color: black;
            }
          }

          .table-wrapper {
            min-height: 100%;
            overflow-y: auto;

            .status {
              border: 1px solid silver;
              border-radius: 3px;
              padding: 3px 0px;
              font-weight: 500;

              &.status-2 {
                border-color: #159bb1;
                color: #159bb1;
              }

              &.status-3 {
                border-color: #009e07;
                color: #009e07;
              }
            }
          }

          .top.level {
            justify-content: start;
            padding: 0 10px;

            .pagination.is-simple {
              flex-direction: row-reverse;
            }
          }
        }
      }
    }
  }
}
</style>
