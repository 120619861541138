<template>
  <div class="Reports__View">
    <EntityListLayout :tabs="tabs" ref="entity" @onNewEntitySave="onNewEntitySave">
      <template #title>משתמשים</template>
      <template #button>
        <!-- <b-button @click="showNewEntity">הוספת דוח חדש</b-button> -->
        <a @click="addUser" class="link-button">
          משתמש חדש
        </a>
      </template>
      <template v-slot:counter="slotProps">
        סה"כ <b>{{ getTotalRecords(slotProps) }} </b>
      </template>
    </EntityListLayout>
  </div>
</template>
<script>
import AdminService from "@/services/AdminService";
import EntityListLayout from "@/components/EntityListLayout.vue";
import UserEntity from "@/views/UserEntity.vue";
import DialogAddUser from '@/components/DialogAddUser.vue';
import {
  ModalProgrammatic as Modal,
} from 'buefy';
// import TableActions from "@/services/TableActionsService";
import FieldService from "@/services/FieldService";
// import FilterService from "@/services/FilterService";
import NewReportEntity from "@/components/Entities/NewReportEntity.vue";
import { saveAs } from "file-saver";

export default {
  name: "AdminSearchReports",
  data() {
    return {
      tabs: [],
    };
  },
  components: {
    EntityListLayout,
  },
  computed: {
    isAdmin() {
      return this.$store.state.auth?.user?.claims?.isAdmin === "True";
    },
    tabName() {
      return this.selectedTab && this.selectedTab.title;
    },
    excelMimeType() {
      return "application/vnd.ms-excel";
    },
    isLeasingCompany() {
      return this.$store.state.profile?.IsLeasingCompany;
    },
    isReady() {
      return this.isLeasingCompany === null;
    },
  },
  created() {
    this.create();
  },
  methods: {
    create() {
      const reportsFields = FieldService.list("users");
      // const reportsfilters = FilterService.list();

      const sideViewEntity = {
        component: UserEntity,
        preview: true,
        readOnly: false,
        title: "פרטי דוח",
        recordUrl: "admin/reports",
        hideRecord: true
      };

      const excelExport = [
        {
          title: "יצוא טבלה",
          action: this.export,
        },
        // {
        //   title: "יצוא מקובץ לפי עיריות",
        //   action: this.exportByMunic,
        // },
        // {
        //   title: "יצוא מקובץ לפי לקוח",
        //   action: this.exportByClient,
        // },
      ];
      const fields = [
        { ...reportsFields.Id, checked: true },
        { ...reportsFields.PrivateName, checked: true },
        { ...reportsFields.LastName, checked: true },
        { ...reportsFields.UserName, checked: true },
        { ...reportsFields.Phone, checked: true },
        { ...reportsFields.Address, checked: true },
        { ...reportsFields.Status, checked: true },
        { ...reportsFields.CreateDate, checked: true },
        { ...reportsFields.UserTypeId, checked: true },
      ];

      this.tabs = [
        {
          id: "AllUsersAdmin",
          title: "כל המשתמשים",
          newEntityComponent: NewReportEntity,
          getDataAsync: this.getAllUsers,
          perPage: 20,
          fields,
          // filters: [],
          sortField: "LastPayDate",
          sortOrder: "desc",
          checkableRows: false,
          // excelExport,
          fastSearch: true,
          fastSearchOptions: {
            propName: "Terms",
            label: "חיפוש משתמש",
          },
          sideViewEntity,
          tableActions: null,
          isAdminArea: true,
          defaultQuery: false
          // defaultQuery: {
          //   IsAscending: false,
          //   SortedBy: "LastPayDate"
          // }
        },
      ];
    },
    async onPlateNumberClick(context) {
      context.default();
    },
    showNewEntity() {
      this.$refs.entity.newEntityMenu();
    },
    onNewEntitySave() {
      this.$refs.entity.onNewEntityCancel();
    },
    export({ loading, query }) {
      loading(true);
      AdminService.exportXslAdmin(query)
        .then((r) => {
          this.saveFile(r.data, "יצוא דוחות ממערכת Betterway");
        })
        .finally(() => {
          loading(false);
        });
    },
    exportByMunic({ loading, query }) {
      loading(true);
      AdminService.exportXsl(query, "Municipalities")
        .then((r) => {
          this.saveFile(r.data, "יצוא דוחות ממערכת Betterway מקובץ לפי עיריות");
        })
        .catch(() => { })
        .finally(() => {
          loading(false);
        });
    },
    exportByClient({ loading, query }) {
      loading(true);
      AdminService.exportXsl(query, "Profiles")
        .then((r) => {
          this.saveFile(r.data, "יצוא דוחות ממערכת Betterway מקובץ לפי לקוחות");
        })
        .catch(() => { })
        .finally(() => {
          loading(false);
        });
    },
    getAllUsers({ cancelToken, ...rest }) {
      return AdminService.getUsers(
        {
          ...rest,
        },
        cancelToken.token
      ).then((r) => ({
        data: {
          TotalResults: r.data.totalResults,
          Items: r.data.items,
        },
      }));
    },
    getAllTransfers({ cancelToken, ...rest }) {
      return AdminService.adminSearchTransfers(
        {
          ...rest,
        },
        cancelToken.token
      );
    },
    getPayments({ cancelToken }) {
      return AdminService.getPayments(cancelToken.token).then((r) => ({
        data: {
          TotalResults: r.data.length,
          Items: r.data,
        },
      }));
    },
    getTotalRecords(slotProps) {
      return slotProps.tab && slotProps.tab.total && slotProps.tab.total;
    },
    saveFile(data, filename) {
      const blob = new Blob([data], { type: this.excelMimeType });
      saveAs(blob, `${filename}.xlsx`);
    },
    checkFields(fields, idxFieldToCheck) {
      const newFields = [...fields];
      newFields.splice(idxFieldToCheck, 1, {
        ...newFields[idxFieldToCheck],
        checked: true,
      });
      return newFields;
    },
    displayFields(fields, fieldsDisplayTrueArr, fieldsDisplayFalseArr) {
      const newFields = [...fields];
      newFields.forEach((field, idx) => {
        if (fieldsDisplayTrueArr.includes(field.Display)) {
          newFields.splice(idx, 1, {
            ...newFields[idx],
            checked: true,
          });
        }
        if (fieldsDisplayFalseArr.includes(field.Display)) {
          newFields.splice(idx, 1, {
            ...newFields[idx],
            checked: false,
          });
        }
      });
      return newFields;
    },
    loadUsers() {
      this.$refs.entity.getData(this.tabs[0]);
    },
    addUser() {
      Modal.open({
        component: DialogAddUser,
        props: {
          reportNumbers: [{ Id: 1, reportNumber: 123 }, { Id: 2, reportNumber: 3456 }],
          store: this.$store
        },
        events: {
          customEvent: () => {
            this.loadUsers();
          }
        },
        canCancel: false,
        hasModalCard: true,
        trapFocus: true,
      });
    }
  },
  watch: {
    isReady(value) {
      if (value) {
        this.create();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.Reports__View {
  height: 100%;

  .link-button {
    background: #ff7a59;
    border: none;
    border-radius: 3px;
    padding: 14px 25px;
    margin-left: 10px;
    color: white;
    cursor: pointer;
  }
}
</style>
