var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "AdminAlertGenerator__View" }, [
    _c("h1", [_vm._v("מחולל התראות עבור דוחות")]),
    _c("div", { staticClass: "AdminAlertGenerator__View__Form" }, [
      _c(
        "div",
        { staticClass: "field" },
        [
          _c(
            "b-field",
            { attrs: { label: "מציג פרופילים מנוהלים" } },
            [
              _c(
                "b-switch",
                {
                  model: {
                    value: _vm.isManaged,
                    callback: function($$v) {
                      _vm.isManaged = $$v
                    },
                    expression: "isManaged"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.isManaged ? "כן" : "לא") + " ")]
              )
            ],
            1
          ),
          _c(
            "b-field",
            { attrs: { label: "סוגי דוחות" } },
            [
              _c(
                "b-switch",
                {
                  model: {
                    value: _vm.isType,
                    callback: function($$v) {
                      _vm.isType = $$v
                    },
                    expression: "isType"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.isType ? "לא שולמו" : "הכל") + " ")]
              )
            ],
            1
          ),
          _c(
            "b-field",
            { attrs: { label: "מספר דוח" } },
            [
              _c("b-input", {
                model: {
                  value: _vm.reportNumber,
                  callback: function($$v) {
                    _vm.reportNumber = $$v
                  },
                  expression: "reportNumber"
                }
              })
            ],
            1
          ),
          _c(
            "b-field",
            { attrs: { label: "בחר פרופיל" } },
            [
              _c(
                "v-select",
                {
                  attrs: {
                    dir: "rtl",
                    label: "Text",
                    options: _vm.users,
                    clearable: true,
                    multiple: true,
                    searchable: true,
                    "close-on-select": false,
                    placeholder: "בחר ערך מהרשימה"
                  },
                  model: {
                    value: _vm.profiles,
                    callback: function($$v) {
                      _vm.profiles = $$v
                    },
                    expression: "profiles"
                  }
                },
                [
                  _c(
                    "div",
                    { attrs: { slot: "no-options" }, slot: "no-options" },
                    [_vm._v("לא נמצאו ערכים דומים")]
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "b-button",
            {
              staticClass: "mt-5",
              attrs: { type: "is-primary", loading: _vm.isLoading },
              on: { click: _vm.importData }
            },
            [_vm._v("סרוק נתוני מיקום")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }