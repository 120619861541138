<template>
  <div class="Municipalities__View">
    <EntityListLayout
      ref="entity"
      :tabs="tabs"
      @onNewEntitySave="onNewEntitySave"
      :noPaging="true"
    >
      <template #title>עיריות</template>
      <template #button v-if="isAdmin">
        <b-button @click="showNewEntity">הוספת עיריה חדשה</b-button>
      </template>
      <template v-slot:counter="slotProps">
        סה"כ <b>{{ getTotalRecords(slotProps) }} </b>
      </template>
    </EntityListLayout>
  </div>
</template>
<script>
import MunicipalitiesService from "@/services/MunicipalitiesService";
import EntityListLayout from "@/components/EntityListLayout.vue";
import FieldService from "@/services/FieldService";
import NewMunicipalityEntity from "../components/Entities/NewMunicipalityEntity.vue";
import AdminMunicipalityEntity from "./AdminMunicipalityEntity.vue";

export default {
  name: "Reports",
  data() {
    return {
      tabs: [],
    };
  },
  components: {
    EntityListLayout,
  },
  computed: {
    tabName() {
      return this.selectedTab && this.selectedTab.title;
    },
    isAdmin() {
      return this.$store.state.auth?.user?.claims?.isAdmin === "True";
    },
  },
  created() {
    const MunicipalityFields = FieldService.list("municipalities");
    const sideViewEntity = {
      component: AdminMunicipalityEntity,
      preview: true,
      readOnly: false,
      title: "פרטי עיריה",
      recordUrl: "admin/municipalities",
      hideRecord: true,
      map: (item) => item,
    };
    this.tabs = [
      {
        id: "MunicipalitiesAdmin",
        title: "רשימת עיריות",
        newEntityComponent: NewMunicipalityEntity,
        getDataAsync: this.getMunicipalities,
        perPage: 20,
        fields: [
          { ...MunicipalityFields.Municipality, checked: true },
          { ...MunicipalityFields.Id, checked: true },
        ],
        fastSearch: true,
        fastSearchOptions: {
          propName: "Name",
          label: "חיפוש לפי שם העיריה",
        },
        sortField: "Id",
        sortOrder: "desc",
        sideViewEntity,
      },
    ];
  },
  methods: {
    showNewEntity() {
      this.$refs.entity.newEntityMenu();
    },
    onNewEntitySave() {
      this.$refs.entity.onNewEntityCancel();
    },
    getMunicipalities(term) {
      return MunicipalitiesService.get(term.Name || "", true).then((r) => ({
        data: {
          Items: r.map((res) => ({ Id: res.Value, Municipality: res.Text })),
          TotalResults: r.length,
        },
      }));
    },
    getTotalRecords(slotProps) {
      return slotProps.tab && slotProps.tab.total && slotProps.tab.total;
    },
  },
};
</script>
<style scoped>
.Municipalities__View {
  height: 100%;
}
</style>
