<template>
  <div class="New__Entity">
    <div class="SideMenu__Header">
      <h1>הוספת עיריה</h1>
    </div>
    <div class="SideMenu__Content">
      <FieldInlineText
        label="שם עיריה"
        :required="$v.model.Name.$anyError"
        v-model="$v.model.Name.$model"
      />
      <FieldInlineText
        label="כתובת"
        :required="$v.model.Address.$anyError"
        v-model="$v.model.Address.$model"
      />

      <FieldInlineSelect
        label='חתימת עו"ד'
        :required="$v.model.NeedLawyerSign.$anyError"
        :options="[
          { Value: true, Text: 'צריך' },
          { Value: false, Text: 'לא צריך' },
        ]"
        null-is-value="false"
        v-model="$v.model.NeedLawyerSign.$model"
      />
      <FieldInlineSelect
        label=' חתימת עו"ד לנהג'
        :required="$v.model.NeedLawyerSignForDriver.$anyError"
        :options="[
          { Value: true, Text: 'צריך' },
          { Value: false, Text: 'לא צריך' },
        ]"
        null-is-value="false"
        v-model="$v.model.NeedLawyerSignForDriver.$model"
      />

      <FieldInlineText
        label="אימייל להסבה"
        v-model="$v.model.TransferEmail.$model"
      />
      <FieldInlineText
        label="לינק לתשלום"
        v-model="$v.model.PaymentUrl.$model"
      />
      <FieldInlineText
        label="TransferBotId"
        :required="$v.model.TransferBotId.$anyError"
        v-model="$v.model.TransferBotId.$model"
      />
      <FieldInlineText
        label="TransferBotAuthorityId"
        :required="$v.model.TransferBotAuthorityId.$anyError"
        v-model="$v.model.TransferBotAuthorityId.$model"
      />
    </div>
    <div class="SideMenu__Footer">
      <div>
        <button :disabled="isLoading" @click="onCancel" class="close" type="">
          ביטול
        </button>
        <b-button :loading="isLoading" @click="onSave" class="create" type="">
          שמור
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import FieldInlineSelect from "@/components/Fields/FieldInlineSelect.vue";
import FieldInlineText from "@/components/Fields/FieldInlineText.vue";
import MunicipalitiesService from "@/services/MunicipalitiesService";
import { required } from "vuelidate/lib/validators";

export default {
  name: "NewMunicipalityEntity",
  components: {
    FieldInlineSelect,
    FieldInlineText,
  },
  validations: {
    model: {
      Name: {
        required,
      },
      Address: {
        required,
      },
      NeedLawyerSign: {
        required,
      },
      NeedLawyerSignForDriver: {
        required,
      },
      TransferEmail: {
        // required,
      },
      PaymentUrl: {
        // required,
      },
      TransferBotId: {
        required,
      },
      TransferBotAuthorityId: {
        required,
      },
    },
  },
  data() {
    return {
      isLoading: false,
      model: {
        Name: null,
        Address: null,
        NeedLawyerSign: null,
        NeedLawyerSignForDriver: null,
        TransferEmail: null,
        PaymentUrl: null,
        TransferBotId: null,
        TransferBotAuthorityId: null,
      },
    };
  },
  methods: {
    onSave() {
      const self = this;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.isLoading = true;
      const data = { ...this.model };

      data.Name = this.model.Name;
      data.Address = this.model.Address;
      data.NeedLawyerSign = this.model.NeedLawyerSign;
      data.NeedLawyerSignForDriver = this.model.NeedLawyerSignForDriver;
      data.TransferEmail = this.model.TransferEmail;
      data.PaymentUrl = this.model.PaymentUrl;
      data.TransferBotId = 1;
      // data.TransferBotId = this.model.TransferBotId;
      data.TransferBotAuthorityId = this.model.TransferBotAuthorityId;

      MunicipalitiesService.addMunicipality(data)
        .then(() => {
          self.$buefy.toast.open({
            type: "is-success",
            message: `העיריה נשמרה בהצלחה!`,
            duration: 10000,
          });
          self.$emit("onSave");
        })
        .catch(() => {
          this.$buefy.toast.open({
            type: "is-error",
            message: "השמירה נכשלה, יש לנסות שוב.",
            duration: 8000,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onCancel() {
      this.$emit("onCancel");
    },
  },
};
</script>

<style scoped lang="scss">
.New__Entity {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.notification {
  position: absolute;
  bottom: 43px;
  width: 100%;
  border-radius: 0;

  ::v-deep .media-content {
    text-align: center;
  }

  &.is-info {
    background-color: #01afb4;
  }
  // &.is-warrent {
  //     background-color: orange;
  // }
}
.SideMenu__Footer {
  justify-content: space-between;
}
</style>
