var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "LessonEntityDetails__Component" },
    [
      _c("FieldInlineText", {
        attrs: { label: "שם וידאו ראשון" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.name,
          callback: function($$v) {
            _vm.$set(_vm.model, "name", $$v)
          },
          expression: "model.name"
        }
      }),
      _c("FieldInlineSelect", {
        attrs: {
          label: "קטגוריה",
          filterable: true,
          clearable: true,
          optionsAsync: _vm.getCategories
        },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.lessonCategoryId,
          callback: function($$v) {
            _vm.$set(_vm.model, "lessonCategoryId", $$v)
          },
          expression: "model.lessonCategoryId"
        }
      }),
      _c("FieldInlineSelect", {
        attrs: {
          label: "סוג",
          filterable: true,
          clearable: true,
          optionsAsync: _vm.getVideoTypes
        },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.videoTypeId,
          callback: function($$v) {
            _vm.$set(_vm.model, "videoTypeId", $$v)
          },
          expression: "model.videoTypeId"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "שם וידאו שני" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.name2,
          callback: function($$v) {
            _vm.$set(_vm.model, "name2", $$v)
          },
          expression: "model.name2"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "שם וידאו שלישי" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.name3,
          callback: function($$v) {
            _vm.$set(_vm.model, "name3", $$v)
          },
          expression: "model.name3"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "שם וידאו רביעי" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.name4,
          callback: function($$v) {
            _vm.$set(_vm.model, "name4", $$v)
          },
          expression: "model.name4"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "קישור ראשון" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.link1,
          callback: function($$v) {
            _vm.$set(_vm.model, "link1", $$v)
          },
          expression: "model.link1"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "קישור שני" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.link2,
          callback: function($$v) {
            _vm.$set(_vm.model, "link2", $$v)
          },
          expression: "model.link2"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "קישור שלישי" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.link3,
          callback: function($$v) {
            _vm.$set(_vm.model, "link3", $$v)
          },
          expression: "model.link3"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "HTML" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.htmlContent,
          callback: function($$v) {
            _vm.$set(_vm.model, "htmlContent", $$v)
          },
          expression: "model.htmlContent"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "תצוגה" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.thumbnailUrl,
          callback: function($$v) {
            _vm.$set(_vm.model, "thumbnailUrl", $$v)
          },
          expression: "model.thumbnailUrl"
        }
      }),
      _c("FieldInlineText", {
        attrs: { type: "number", label: "משך הוידאו" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.durationInSeconds,
          callback: function($$v) {
            _vm.$set(_vm.model, "durationInSeconds", $$v)
          },
          expression: "model.durationInSeconds"
        }
      }),
      _c(
        "div",
        { staticClass: "checkbox-container" },
        [
          _c(
            "b-switch",
            {
              staticClass: "ltr",
              on: { input: _vm.onChange },
              model: {
                value: _vm.model.status,
                callback: function($$v) {
                  _vm.$set(_vm.model, "status", $$v)
                },
                expression: "model.status"
              }
            },
            [_vm._v(_vm._s(_vm.model.status ? "פעיל" : "לא פעיל"))]
          ),
          _c(
            "b-switch",
            {
              staticClass: "ltr",
              on: { input: _vm.onChange },
              model: {
                value: _vm.model.isInteractiveVideo,
                callback: function($$v) {
                  _vm.$set(_vm.model, "isInteractiveVideo", $$v)
                },
                expression: "model.isInteractiveVideo"
              }
            },
            [
              _vm._v(
                _vm._s(
                  _vm.model.isInteractiveVideo
                    ? "אינטראקטיבי"
                    : "לא אינטראקטיבי"
                )
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }