<template>
    <div class="DialogAddLaw__Component">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">{{ row ? 'עדכון' : 'הוספת' }} מקבץ פסיקה</p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">
                <div class="DialogAddLaw__Component__Loading">
                    <FieldInlineText label="שם" @onChange="onChange" v-model="model.name" />
                    <FieldInlineSelect label="בחר חוק מהרשימה" :filterable="true" :clearable="true"
                        :reduce="item => item" :optionsAsync="getVerdictSummaries" v-model="chosenLaw" />

                    <div v-if="chosenLaw" class="new-law">
                        <div class="item">
                            <p>{{ chosenLaw.Text }}</p>
                            <input type="number" v-model="chosenLaw.sortOrder">
                            <div style="cursor: pointer" @click="addVerdictItem()" class="add">
                                <b-icon class="" pack="fa" icon="plus"></b-icon>
                            </div>
                        </div>
                    </div>

                    <div class="items">
                        <div v-for="item in model.verdictCollectionItems" :key="item.id" class="item">
                            <p>{{ item.verdictSummaryName }}</p>
                            <input v-model="item.sortOrder" type="number">
                            <div @click="deleteVerdictItem(item.verdictSummaryId)" class="delete"></div>
                        </div>
                    </div>
                </div>
            </section>
            <footer class="modal-card-foot">
                <b-button :loading="isLoading" @click="save" label="שמור" type="is-primary" />
                <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
            </footer>
        </div>
    </div>
</template>

<script>
import { ToastProgrammatic as Toast } from "buefy";
import FieldInlineText from "./Fields/FieldInlineText.vue";
import FieldInlineSelect from "./Fields/FieldInlineSelect.vue";
import AdminService from '../services/AdminService';

export default {
    name: "DialogReportDriverAssign",
    props: ["store", "row"],
    components: {
        FieldInlineText,
        FieldInlineSelect
    },
    computed: {
    },
    created() {
        if (this.row) this.getVerdict(this.row.id);
    },
    data() {
        return {
            isLoading: false,
            model: {
                id: null,
                name: null,
                verdictCollectionItems: [],
            },
            chosenLaw: null
        };
    },
    methods: {
        getVerdict(id) {
            AdminService.getVerdictCollections(id)
                .then((r) => {
                    this.model = r.data;
                });
        },
        deleteVerdictItem(id) {
            const idx = this.model.verdictCollectionItems.findIndex((i) => i.verdictSummaryId === id);
            this.model.verdictCollectionItems.splice(idx, 1);
        },
        addVerdictItem() {
            const newItem = {
                verdictSummaryName: this.chosenLaw.Text,
                verdictSummaryId: this.chosenLaw.Value,
                sortOrder: this.chosenLaw.sortOrder
            };
            if (this.row) {
                this.model.verdictCollectionItems.unshift(newItem);
            } else {
                this.model.verdictCollectionItems.push(newItem);
            }
            this.chosenLaw = null;
        },
        getVerdictSummaries() {
            return () =>
                AdminService.getVerdictSummaries().then((r) =>
                    r.data.map((i) => ({ Value: i.id, Text: i.name, sortOrder: 1 }))
                );
        },
        save() {
            if (this.model.name) {
                this.isLoading = true;
                if (this.row) {
                    AdminService.updateVerdictCollection(this.model.id, {
                        ...this.model
                    })
                        .then(() => {
                            Toast.open({
                                type: "is-success",
                                message: "הפעולה בוצעה!",
                                duration: 4000,
                            });
                            this.$emit("close");
                            window.location.reload();
                        }).catch(() => {
                            Toast.open({
                                type: "is-danger",
                                message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
                                duration: 8000,
                            });
                        }).finally(() => {
                            this.isLoading = false;
                        });
                } else {
                    AdminService.createVerdictCollection({
                        ...this.model
                    })
                    .then(() => {
                        Toast.open({
                            type: "is-success",
                            message: "הפעולה בוצעה!",
                            duration: 4000,
                        });
                        this.$emit("close");
                        window.location.reload();
                    }).catch(() => {
                        Toast.open({
                            type: "is-danger",
                            message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
                            duration: 8000,
                        });
                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
            }
        },
        onChange() { },
    },
};
</script>

<style scoped lang="scss">
.items {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.item {
    display: flex;
    align-items: center;
    gap: 10px;

    p {
        flex: 1;
    }

    input {
        width: 50px;
    }
}

// .clauses {
//     display: flex;
//     justify-content: flex-start;
//     flex-wrap: wrap;
//     max-height: 400px;
//     overflow: auto;
// }

// .clause {
//     min-width: 40px;
//     height: 30px;
//     background: #fff;
//     border: 1px solid #333;
//     border-radius: 4px;
//     color: #333;
//     padding: 5px;
//     margin: 5px 0 5px 10px;
// }</style>
