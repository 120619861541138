var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Report__Entity" },
    [
      _c("Entity", {
        ref: "entity",
        attrs: {
          "entity-type": "Report",
          "entity-id": _vm.reportId,
          innerComponent: _vm.innerComponent,
          innerComponentTitle: "פרטי דוח",
          title: "דוח תעבורה",
          "back-button-text": "רשימת דוחות",
          "back-button-url": !_vm.isAdminWithoutProfileId
            ? "reports"
            : "admin/profiles",
          widgets: _vm.widgets,
          actions: _vm.actions,
          preview: _vm.preview,
          "read-only": _vm.readOnly,
          entityReady: _vm.entityReady,
          activities: []
        },
        on: { onFormSave: _vm.onFormSave, onFormDiscard: _vm.onFormDiscard },
        scopedSlots: _vm._u([
          {
            key: "mandatory",
            fn: function() {
              return [
                _c(
                  "div",
                  {
                    staticClass: "Accident__Entity__Details__BaseDetails__Stage"
                  },
                  [
                    _c("span", { staticClass: "ltr" }, [_vm._v("מספר דוח:")]),
                    _c(
                      "span",
                      {
                        staticClass:
                          "\n            Accident__Entity__Details__BaseDetails__Stage__Value\n            value\n            has-text-weight-bold\n          "
                      },
                      [_vm._v(_vm._s(_vm.ReportNumber))]
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "Accident__Entity__Details__BaseDetails__Stage"
                  },
                  [
                    _c("span", { staticClass: "ltr" }, [_vm._v("מספר רכב:")]),
                    _c(
                      "span",
                      {
                        staticClass:
                          "\n            Accident__Entity__Details__BaseDetails__Stage__Value\n            value\n            has-text-weight-bold\n          "
                      },
                      [_vm._v(_vm._s(_vm.Vehicle.Name))]
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "Accident__Entity__Details__BaseDetails__Stage"
                  },
                  [
                    _c("span", { staticClass: "ltr" }, [_vm._v("עיריה:")]),
                    _c(
                      "span",
                      {
                        staticClass:
                          "\n            Accident__Entity__Details__BaseDetails__Stage__Value\n            value\n            has-text-weight-bold\n          "
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.Municipality && _vm.Municipality.Name)
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "Accident__Entity__Details__BaseDetails__Stage flex-between"
                  },
                  [
                    _c(
                      "span",
                      [
                        _vm._v("זמן קבלת הדוח "),
                        _c("FieldDateHourInline", {
                          staticClass:
                            "\n            Accident__Entity__Details__BaseDetails__Stage__Value\n            value\n            has-text-weight-bold\n          ",
                          attrs: { value: _vm.DateTaken }
                        })
                      ],
                      1
                    ),
                    _c("b-tooltip", { attrs: { label: "לחץ לתשלום" } }, [
                      _vm.PaymentUrl && _vm.isEntityPage
                        ? _c(
                            "a",
                            {
                              staticClass: "Pay__Icon",
                              attrs: { target: "_blank", href: _vm.PaymentUrl }
                            },
                            [
                              _c("i", [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/payment.svg"),
                                    alt: ""
                                  }
                                })
                              ])
                            ]
                          )
                        : _vm._e()
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }